.admin-pagecreator-drag_and_drop, .shopify-leadslidesdk-pages-builder {
  min-height:100%;
  background:white;


  #sortable-blocks
  {
    .custom {
      display:none;
    }
  }

  .nopadding {
    padding:0px!important;
  }

  #tabSwitcher {
    margin-bottom:20px;
    width:100%;

    ul {

      li {
        display:block;
        padding:5px 10px;
        margin:0px 4px;
        float:left;
        color:$dark-grey-four;
        font-size:15px;
        text-align:center;

        border-bottom:4px solid transparent;

        &:hover {
          border-bottom: 4px solid $btn-primary-border;
          cursor: pointer;
          cursor: hand;
        }
      }

      li.active {
        border-bottom: 4px solid $btn-primary-border;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          cursor: hand;
        }
      }
    }

  }
  .header-area {
    margin-bottom: 20px !important;
  }
  #dragShadow {
    background: black;
    opacity: 0.6;
    width:100%;
    height: 100%;
    position: fixed;
    content: '';
    margin:auto ;
    top:0;
    bottom:0;
    left:0;
    right:0;
    left:0;
    z-index:20;
    display:none;
  }

  #pagePreview {
    position: absolute;
    width:100%;
    min-height:100%;
    border-radius:5px;
    background: white;
    top:10px;
    padding:5px 0;
    left:0;
    right:0;
    margin:auto;
    z-index:1900;
    display:none;

    .hidePreview {
      position:absolute;
      top:0;
      right:5px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      width: 30px;
      height: 30px;
      display: block;
      border-radius: 100%;
      padding: 0;
      margin: 0;
      border: none;
      background: #62727f;
      color: white;
      z-index:900;
    }
  }

  .ui-draggable-dragging {
    z-index:1500;
    opacity: 1;
  }
  .ui-sortable-helper {
    z-index:1500;
    opacity: 1;
  }
  #pageBuilder {
    
    .headingF {
      padding:20px;
      background: $light-grey-two;
      margin-bottom:20px;

      h2 {
        color:$dark-grey-four;

        .reg {
          color: $dark-grey-seven;
          font-weight: normal;
        }
      }
    }

    .sortable-holder {
      padding:0;

      div {
        position: relative;
      }

      .removeBlock {
        position:absolute;
        top:0;
        right:5px;

        line-height: 30px;
        text-align: center;
        font-size: 16px;
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 100%;
        padding: 0;
        margin: 0;
        border: none;
        background: #62727f;
        color: white;

      }
      .leadslide-highlight {
        //z-index:1200;
        //position: relative;
      }

      #firstLoader {
        position: absolute;
        margin: auto;
        left:0;
        right:0;
        top:0;
        bottom:0;
        height:100%;
        width:100%;
        background: white;
        z-index:900;
        display:none;

        .centeredContent {
          text-align: center;
          margin-top:30%;
          color:$btn-info-border;
          font-size:38px;

          i {
            font-size:100px;
          }
        }
      }
    }
    #firstLoader.inactive {
      display:block;

      .centeredContent {
        color:$dark-grey-seven;
      }
    }

    .direction-holder {
      font-size:60px;
      color:$btn-info-border;
      text-align: center;
      position: relative;
      height:700px;

       i {
         position: absolute;
         top:0;
         bottom:0;
         left:0;
         right:0;
         margin:auto;
         height:60px;
       }
    }

    #sortable-blocks {
      border: 1px dashed $dark-grey-seven;
      min-height:700px;
      padding:5px;

      div {
        margin-bottom: 5px;

        &:hover {
          cursor: pointer;
          cursor: hand;

        }
      }
    }

    #sortable-site {
      border: 1px dashed $dark-grey-seven;
      min-height:700px;
      padding:5px;

      div {
        width:100%;

        img {
          width:100%;
          max-width:100%!important;
          height:auto;
        }
      }

    }
  }

  #pagePreview {
    //margin-top:20px;
    //width:100%;

    .container, .full-container {
      padding: 60px 0;
      position: relative;
    }
    .full-container {
      position: relative;
      width:100%;
      margin:auto;

      &:after {
        content: " ";
        display: table;
        clear:both;
      }

      &:before {
        content: " ";
        display: table;
      }
    }
    .imagetextFull, .portfolio-block {
      .img-block {
        img {
          width:100%;
          height:auto;
        }
      }
    }
    .removepadding {
      padding:0 !important;
    }

    .remove {
      display:none;
    }

    .outer-block {
      clear:both;
    }
  }
}

//page styles

.list-general-block {
  .blockBlockList {
    margin:10px 0px;
    padding:0px;

    li {
      display:block;
      line-height:40px;
      font-size:18px;
      margin-bottom:5px;
      background:none;
      clear:both;
      color:#777777;

      .iconHolder {
        float:left;
        margin-right:20px;
        min-width: 40px;
        text-align:center;

        i {
          color:#777777;
          font-size:40px;
          line-height:40px;
        }
      }

    }
  }
}

.navigaion-thin-outerblock {
  padding-top:10px;
  padding-bottom:10px;

  a {
    font-size:15px;
  }
}

.outer-block.testimonial-block {
  padding-bottom:120px;
}

.testimonialBlockLeft {

  .quotation {
    &:before {
      position: absolute;
      content: '';
      left: -16px;
      top: 30px;
      z-index: 199;
      width: 16px;
      height: 0px;
      border-style: solid;
      border-width: 16px 16px 16px 0px;
      border-color: transparent #f9f9f9 transparent transparent;
    }
  }
}

.testimonialBlockRight {
 .quotation {
   &:after {
     position: absolute;
     content: '';
     right: -31px;
     top: 30px;
     z-index: 199;
     width: 16px;
     height: 0px;
     border-style: solid;
     border-width: 16px 16px 16px 16px;
     border-color: transparent transparent transparent #f9f9f9;
   }
 }

}

.testimonialBlockLeft, .testimonialBlockRight {
  position: relative;
  padding-top:40px;
  padding-bottom:40px;
  clear:both;

  .image {
    text-align:center;
    img {
      width:100%;
      max-width:155px;
      max-height:155px;
    }
  }

  .quotation {
    background:#f9f9f9;
    padding-top:40px;
    padding-bottom:40px;
    position: relative;
    .review {

    }
    p {
      font-size:18px;
      text-align:center;
      color:#777777;
      font-family:open sans,montserrat,arial,sans-serif;
    }

    .author {
      color:#7cc576;
    }
  }
}

.social-block {

  ul {
    margin:0;
    padding:0;
  }

  ul.block {
    li {
      display:block;
    }
  }

  ul.inline {
    li {
      display:block;
      float:left;
    }
  }
}

.social-block.circular {
  ul {


    li {
      width:40px;
      height:40px;
      line-height:40px;
      background:#222222;
      text-align:center;
      margin-left:2px;
      margin-right:2px;
      border-radius:50px;

      a {
        color:white;
        line-height:40px;
        display:block;

        i {
          line-height:40px;
        }
        &:hover {
          opacity:0.9;
        }
      }
    }
  }
}
//page mobile styles
@media only screen and (max-width: 990px) {
  .list-image-block {
    background-image: none!important;
  }

  .column-block {
    margin-bottom:40px;
  }

  .testimonialBlockLeft {
    .quotation {
      margin-top:40px;
    }
  }
  .testimonialBlockRight {
    .quotation {
      margin-bottom:40px;
    }
  }
}

.outer-block.imagetextFull {
  .column-block {
    p {
      font-family:open sans,montserrat,arial,sans-serif;
      font-weight:200;
      font-size:18px;
    }
  }
}


//redesign for menu
.pagecreator-nav {
  #individualOpts {
    padding:0px;

    //all options
    .options {

    }

    .innerOptionsCL {

      .innerItem {
        display:none;

        .backOneNav {
          font-size:40px;
          color:white;
          line-height:100px;
          display:block;
        }

        .col-md-11 {
          min-height: 100px;
          padding-top:20px;
          padding-bottom:20px;

          //Internal options bellow
          .remove-image {
            width:auto!important;
          }

        }
      }
    }

    //internal options
    .intOptions {
      margin:0;
      padding:0;
      width:100%;

      li {
        display:block;

         a {
           font-size:60px;
           line-height:140px;
           display:block;
           color:$dark-grey-six;
         }

        &:hover {
          a {
            background:$dark-grey-ten;
            color:white;
          }
        }
      }
    }
  }
}
@import "popup-defaults.scss";
.single-template-page {

  .loader {
    position: absolute;
    top:0;
    bottom: 0;
    right:0;
    left:0;
    margin:auto;
    text-align: center;
    display: none;

    i {
      position: absolute;
      font-size:40px;
      top:45%;
    }

  }
  background: $dark-grey-nine;
  .ls-logo {
    background: black;
    text-align: center;
    padding:20px 0;
    h1 {
      padding:0;
      margin:0;
    }

  }
  .ls-template-nav {
    background: white;
    padding-left:0;
    padding-right:0;
    .template-options {
      width:100%;
      padding:0;
      margin-right:0;
      background: $dark-grey-eight;

      div.option {
        float:left;
        width:100px;
        height:100px;
        text-align: center;
        a {
          font-size:48px;
          color: $dark-grey-seven;
          display:block;
          line-height: 100px;
          &:hover {
            background: $dark-grey-seven;
            color:white;
          }
        }
      }
    }

    .single-template-options {

      .option {
        display: block;
        float: left;
        padding: 15px;
        color: #62727f;
        font-size: 34px;

        a {
          color:$dark-grey-six;

          &:hover {
            color:$dark-grey-five;
          }
        }
      }
    }

    .individual-options {
      padding:20px 0;
      display:none;
      background: $dark-grey-eight;
      color:white;

      .dropzone-wrapper {
        width:100%;
        height:150px;
        overflow-x:hidden;
        overflow-y: scroll;

        i {
          font-size:3.2vw;
        }
        .dz-success {
          display:none;
        }
        .dropzone.dz-started .dz-message {
          display:block;
        }
      }
      #imageScroller, #imageScrollerImage {
        width:100%;
        height:127px;
        padding:0px;
        overflow-x:scroll;
        overflow-y: hidden;

        .image-holder {
          .image-item-none {
            i {
              font-size:50px;
            }
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 115px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              i {
                color:$light-grey-one;
              }
            }
          }
          .image-item {
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 90px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              img {
                opacity: 0.5;
              }
            }
            img {
              max-height: 90px;
              width:auto;
            }
          }
        }
      }

      .col-md-1 {
        padding:0;
        text-align: center;
        .backNav {
          color: $dark-grey-nine;
          font-size:4.2vw;
          display:block;
          &:hover {
            color:white;
          }
        }
      }

      .colourpicker {
        min-height: 26px;
        line-height: 26px;
      }

      .admin-checklist {
        width:100%;
        margin:0;
        padding:10px 0 0 0;
        li {
          .title {
            h3 {
              margin:0 0 10px 0;
            }
          }
          display:block;
          ul {
            padding-left:20px;
          }
          a {
            color:white;
          }
        }
        li.checked {
          a {
            text-decoration: line-through;
          }
          i {
            color:$valid-color;
          }
        }
        li.unchecked {
          i {
            color:$invalid-color;
          }
        }
      }
      .options {
        display: none;
      }
    }

  }

  //Specific fixes
  .dropzone .dz-message {
    text-align: center;
    margin: 0;
  }

  .popUpContainment {
    position:relative;
    margin:2% auto;

  }
}


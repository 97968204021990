@import "templates/single";
@import "pagecreator/default";
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.ls-modal-items {
  padding:10px;
  h1,h2,h3,h4,h5,h6 p, label {
    color:$light-grey-six;
  }

  .modal-header {
    padding:0px;
    margin:0px;
    color:$light-grey-six;

  }

  .modal-title {
    padding-top:0px;
    color:$light-grey-six;
  }

  .modal-footer {
    padding-bottom:40px;
    color:$light-grey-six;

  }
}

.ls-form-items {
   p {
     color:$light-grey-six!important;
   }

  .form-group {
    margin-bottom:20px;
  }
  button {
    font-size:17px;
    line-height:1.1;
    padding:12px 25px;
  }
  label {
    font-size:17px;
    line-height:1.1;
    color:$light-grey-six;

    .optional {
      color:$light-grey-two;
    }
  }
  input[type=text], input, textarea, select {
    border-radius:0px;
    height:auto;
    font-size:18px;
    -webkit-appearance: none;
  }
}

.nav-tabs {
  border:none;
  li {
    a:focus {
      background:none;
      border:none;
    }
    a {
      color:$dark-grey-twelve;

      &:hover {
        background:none;
        border:none;
        border-bottom:4px solid #2e6da4;
      }
    }
  }
  li.active {
    a:focus {
      background:none;
      border-top:none;
      border-right:none;
      border-left:none;
      border-bottom:4px solid #2e6da4;
    }
    a {
      background:none;
      border:none;
      border-bottom:4px solid #2e6da4!important;

      &:hover {
        background:none;
        border:none;
        border-bottom:4px solid #2e6da4;
      }
    }
  }
}

.title_underline {
 h3 {
   font-size:18px;
   font-family:"Open Sans", helvetica, arial, sans-serif;
   font-weight:200;
   padding-bottom:20px;
   border-bottom:1px solid $dark-grey-one;
 }
}

.removable-products {
  .selected-products {
    padding:0px;
    margin:0px;
    li { 
      display: block; 
      margin-bottom:10px;
      position: relative;
      
      .remove-product {
        position: absolute;
        top:5px;
        right:5px;
        width:15px;
        height:15px;
        line-height:15px;
        font-size:14px;
        text-align: center;
      }
    }
    
    
  }
}
ul.leadslide-list {
  background: none;
  margin:0 0 40px 0;
  padding:0px;

  li {
    display: block;
    padding:25px 0;
    font-family:'open sans', helvetica, arial, sans-serif;
    border-bottom:1px solid $light-grey-one;

    a,p,h2,h3,h4,h5,h6 {
      font-family:'open sans', helvetica, arial, sans-serif;
    }
    i {
      font-size:42px;
    }

    .title {
      color:$dark-grey-ten;
      font-size:20px;
      line-height:2;
      font-weight:600;
      margin-bottom: 0em;
    }
    a {
      color:$dark-grey-ten;
      font-size:20px;
      line-height:1.2;
      font-weight:600;
      margin-bottom: 1.5em;

      &:hover {
        opacity:0.7;
      }
    }
  }
}

.line-space-9 {
  letter-spacing:0.9px;
}

.hidden-nav {
  display:none;
}

.fs20 {
  font-size:20px;
}

.ptsans-light {
  font-family:"PT Sans", helvetica, arial, sans-serif;
  font-weight:400;
}

.checkbox-list {
  padding:0px;
  margin:0px;

  li {
    display: block;
  }
}
.mb10 {
  margin-bottom:10px;
}
.opensans-light {
  font-family:"Open Sans", helvetica, arial, sans-serif;
  font-weight:400;

  a {
    color:$dark-grey-one;
  }
}

.opensans-strong {
  font-family:"Open Sans", helvetica, arial, sans-serif;
  font-weight:700;

  a {
    color:$dark-grey-one;
  }
}

.ls-new-block-photo-list {
  padding:0px;
  margin:0px;
  li {
    display: block;

    .image-item {
      position: relative;
      .action-item {
        background:rgba(255,255,255,.8);
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        height:100%;
        display: none;
        text-align: center;

        button {
          margin-top:45%;
        }
      }
      &:hover > .action-item {
        display: block;
      }
    }

    .text-item {
      clear:both;
      padding:10px 0;
      text-align:center;
      font-size:18px;
      font-family:'open sans', helvetica, arial, sans-serif;
      font-weight:200;

    }
  }
  li.active {
    .image-item {
      border:2px solid $green-one !important;
    }

  }
}
.ls-block-design {
  .item {
    border:1px solid $dark-grey-one;
    a {
      color:$dark-grey-one;
      &:hover {
        color: $light-grey-four;
        text-decoration: none;
        background:rgba(0,0,0,0.6);
      }
    }
    &:hover {
      border:1px solid $dark-grey-two;
      cursor: hand;
      cursor: pointer;
    }
  }
}
.hidden-nav.active {
  display: block;
}
.minicolors input[type=hidden] + .minicolors-swatch
{
  border: 1px solid #666;
  box-shadow: none;
  background: none;
  width:30px;
  height:30px;
  border-radius: 100%;

  .minicolors-swatch-color {
    border-radius:100%;
  }
}

.shopify-leadslidesdk-popup-creator-index {
  .minicolors-position-left .minicolors-panel {
    //right:0;
    left:-90px;

  }
  #popupBlocksHolder .jqplaceholder {
    display: none!important;
  }

  #creatorHolder {
    .editOptions {
      display:none;
    }
  }

  .leadslide-popup-holder {
    border:1px dashed #48a9ff;
    min-height:400px;
  }
  ul.col-select {
    width:100%;
    margin:0px;
    padding:0px;
    li {
      position: relative;
      display: block;
      .inner {
        display: none;
        position: absolute;
        top:10px;
        left:10px;
        right:10px;
        bottom:10px;
        background: rgba(0,0,0,.7);
        //width:100%;
        //height:100%;
        text-align:center;
        border-radius:10px;
        .select-box {

          a {
            line-height:250px;
            display: block;
            font-size:24px;
            color:white;
          }
        }
      }
      &:hover {
        .inner {
          display: block;
        }
      }
    }

  }
  .admin-content {
    background:#494949!important;
  }
}

.popup-creator {
  .popframe {
    padding-top:17px;
    padding-bottom:17px;
    background:#494949;
  }
  #creatorHolder, #popupBlocksHolder {
    background:#f8f8f8;
    min-height:400px;
    overflow-y: auto;
    .popupContainmentArea {
      width:100%;
      top:0;
    }
  }

  #popupBlocksHolder {
    margin:0;
    padding:0;
    max-height:400px;
    overflow-y: auto;
    li {
      display: block;
      margin-bottom:10px;

      &:hover {
        cursor: pointer;
        cursor: hand;
      }
    }
  }
}
.analytic-options {
  .section {
    margin-bottom:20px;

    .icon {
      background:#f8f8f8;
      text-align:center;
      width:90px;
      height:90px;
      line-height:90px;
      border-radius:10px;
      font-size:50px;
      max-width:32%;
      float:left;
      color:$dark-grey-twelve;
    }
    .data {
      margin-left:15px;
      max-width:65%;
      float:left;
      .data-text {
        font-size:24px;
        line-height:1.4;
        color:$light-grey-six;
      }
      .data-number {
        font-size:34px;
        line-height:1.4;
        color:$light-grey-six;
      }
    }
  }
}
.gallery-tabs {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.5); }
.innerItem {
  .col-sm-11, .col-md-11
  {
    padding-bottom:10px;
  }
}
.startDrag {
  &:hover{
    cursor: pointer;
    cursor: hand;
  }
}
.modal-header,.modal-footer {
  border:none;
}
.editPopSizes {
  border:none;
  background:none;

  margin-top:2px;
  i {
    font-size: 30px;
  }
}
#editUpsell {

  .upsell-checklist {
    padding:0;
    margin:0;

    li {
      display: block;

      .fa-check {
        color: #3b8049;
      }

      .fa-times {
        color: #d14d48;
      }
    }
  }
  .ls-close-options {
    position: absolute;
    top:10px;
    right:10px;
    color:white;
    font-size:16px;
    &:hover {
      opacity:0.8;
      cursor: hand;
      cursor: pointer;
    }
  }
  .ls-upsell-options {
    background:rgba(0,0,0,0.9);
    width:100%;
    height:380px;
    position: fixed;
    top:14.1%;
    bottom:0;
    left:0;
    right:0;
    padding:20px;
    z-index:99;

    .ls-option {
      h2,h3,h4,h5,p,ul li, a, label {
        color:white;
      }
    }

  }

  .ls-upsell-menu {
    width:100%;
    margin:0px;
    padding:0px;

    li {
      display: block;
      float:left;
      padding:15px 25px;
      font-size:30px;
      background:#31373D;
      color:white;
      text-align:center;

      &:hover {
        color:#35DBB2;
        background: #444c55;
        cursor: pointer;
        cursor: hand;
      }
    }
  }
}
.style-menu-config {
  position: absolute;
  top:68px;
  bottom:0px;
  left:0;
  z-index:90;

  background:rgba(0,0,0,0.7);
  .config-menu {
    padding:0px;
    margin:0px;
    li {
      display: block;
      padding:15px;
      border-bottom:1px solid #f8f8f8;
      color:white;
      &:hover {
        cursor: pointer;
        cursor: hand;
        color:$light-grey-six;
      }
    }
  }

  .opt-config {
    display: none;
    width:100%;
    height:100%;
    padding:15px;
    background:rgba(0,0,0,0.7);

    label {
      color:white;
    }
    ul {
      margin:0px;
      padding:0px;
      li {
        display: block;
        margin:0px;
        padding:15px;
      }
    }
  }
}
.option-menu {
  margin:0px;
  padding:0px;
  background:$dark-grey-one;
  border-radius:4px 4px 0 0;
  li {
    margin:0px;
    padding:0px;
    display: block;
    text-align: center;

    a {
      text-decoration: none;
      font-size:48px;
      display: block;
      color:$light-grey-six;

      &:hover {
        text-decoration: none;
        color:$light-grey-one;
      }
    }

    &:hover {
      background:$dark-grey-four;
    }
  }

}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}



.slider.round:before {
  border-radius: 50%;
}


#popHtmlIpad {
  background: url('../images/shopify/apps/leadslide/ipad_n.png') center center no-repeat;
  width:636px;
  margin:auto auto 100px auto;
  height:903px;
  overflow: hidden;
  padding:100px 45px;

  .popupContainmentArea.mlar {
    width:85%!important;
    top:5%;
    max-height:740px;
    overflow:auto;

    .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10 {
      width:100%!important;
      margin-left:0px!important;
      margin-right:0px!important;
    }
  }
}
#popHtmlPhone {
  background: url('../images/shopify/apps/leadslide/iphone_n.png') center center no-repeat;
  width:381px;
  margin:auto auto 100px auto;
  padding:111px 23px 100px 23px;
  height:803px;
  overflow: hidden;
  .popupContainmentArea {
    margin-top:20px;
  }

  .popupContainmentArea.mlar {
    width:100%!important;
    top:0;
    max-height:584px;
    overflow:auto;

    .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10 {
      width:100%!important;
      margin-left:0px!important;
      margin-right:0px!important;
    }
  }
}
ul.responsiveSelection {
  margin:0;
  padding:0;
  li {
    display: block;
    padding:0px;
    text-align:center;

    a {
      font-size: 60px;
      line-height: 140px;
      display: block;
      color: $dark-grey-six;
      &:hover {
        background:$dark-grey-ten;
        color:white;
      }
    }
    a.active {
      background:$dark-grey-ten;
      color:white;
    }
  }
}

ul.template-select {
  width:100%;
  display: block;
  padding:0px;
  margin:0px;
  li {
    display: block;
  }

  li .item {
    background-color:$dark-grey-ten;
    height:150px;
    margin-bottom:20px;
    background-size:auto 100%;
    background-repeat:no-repeat;
    background-position: center center;

    &:hover {
      cursor: hand;
      cursor: pointer;
    }
  }

  li .item.active {
    -webkit-box-shadow:  0px 0px 10px green;
    -moz-box-shadow:  0px 0px 10px green;
    box-shadow:  0px 0px 10px green;
  }
}

.ls-tab-menu {
  width:100%;
  display: block;
  padding:0px;
  margin:0px;
  li {
    display: inline-block;
    margin-right:10px;

    a {
      color:$dark-grey-nine;
      font-size:16px;
      display: block;
      .fa {
        margin-left:10px;
        color:$dark-grey-nine;
      }
    }
  }


  li.active, li.complete {
    a {
      color:$dark-grey-ten;
      .fa {
        color:$dark-grey-nine;
      }
    }
  }
  li.complete {
    a {
      color:$dark-grey-ten;
      .fa {
        color:$dark-grey-ten;
      }
    }
  }
}

.ls-tab-selection {
  display: none;
  width:100%;
  margin:20px 0;
  padding:20px 0;
}

.ls-tab-selection.active {
  display: block;
}

.ls-table {
  tr {
    border-top:none;
    border-bottom:none;
    padding:20px 0;
    background:white;

    th {
      text-align: left!important;
      vertical-align: middle !important;
      padding:25px 8px 10px 8px !important;
      border:none;
      border:none!important;
    }
    td {
      text-align: left!important;
      border:none;
      padding:20px 8px!important;

      vertical-align: middle !important;
      table {
        tr {
          border:none;
          td {
            vertical-align: middle !important;

          }
          th {
            vertical-align: middle !important;
          }
        }
      }
    }
  }
  .alert-success {
    background-color: #dff0d8!important;
    border-color: #d6e9c6;
    color: #3c763d;
  }
}
#emailBuilderBlocks {
  background:white;
  padding-top:20px;
  padding-bottom:20px;

  .block-insert {
    
    text-align: center;
    margin-bottom:15px;
    img {
      max-width: 100%;
    }
    
    &:hover {
      cursor: pointer;
      cursor: hand;
    }
  }
}

#emailBuilderHolder {
  background: white;
  min-height:780px;
  .edit-block {
    position: relative;
  }
  .toolbar {
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    display:none;
    background: $blue-two;

    button {
      float:left;
    }

  }
  
  .edit-block {
    &:hover {
      cursor: pointer;
      cursor: hand;
      > .toolbar {
        display: block;
      }
    }
  }

}

.screenLoader {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;

  width:100%;
  height:100%;
  z-index:5000;
  background:rgba(255,255,255,0.9);

  .loading-text {
    //font-size:46px;
    //text-align:center;
    //position: absolute;
    //top:25%;
    //width:100%;
    //color:#ccc;

  }

  .loader-wrapper {
    width:150px;
    height:150px;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    margin:auto;
  }
  .loader {
    color: #666666;
    font-size: 20px;
    margin: 55px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.8s infinite linear;
    animation: load4 1.8s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    display: block!important;
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
}
ul.pagination {
  margin:20px auto!important;
}
.angSdkPopup, .angSdkPage, .shopify-leadslidesdk-campaigns-new, .shopify-leadslidesdk-campaigns-edit {
  .tab-menu-category, ul.imageGal {
    ul {
      li {
        display:block;
      }
    }
  }

  .pagination-holder {
    ul {
      margin:20px auto!important;
    }
  }

  #videoPreviewerAng {
    position: absolute;
    z-index:1900;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.7);
    width:100%;
    height:100%;
    display: block;

    .preview-inner {
      width:300px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 480px;
      height: 270px;
      margin: auto;
      z-index: 500;
      background: black;
      background: rgba(0, 0, 0, 0.5);
      padding: 20px;
    }

    .closePreview {
      position: absolute;
      width: 30px;
      height: 30px;
      font-size: 22px;
      color: white;
      background: #666;
      border-radius: 100%;
      border:none;
      top: 5px;
      right: 5px;
      text-align: center;
      line-height: 27px;
      z-index: 200;

      &:hover {
        opacity:0.9;
        cursor: hand;
        cursor: pointer;
      }
    }
  }
}

.campaign-menu {
  margin-bottom:20px;
}
.campaign-items {
  margin-bottom:20px;

  .remove-item {
    width:25px;
    height:25px;
    border-radius: 100%;
    color:white;
    position: absolute;
    z-index:1;
    top:0px;
    right:17.5px;
    text-align: center;

    i {
      font-size:25px;
    }

    &:hover {
      opacity:0.9;
      cursor: pointer;
      cursor: hand;
    }
  }
  .item-holder {
    width:100%;
    display: table;
    height:150px;

    .item {
      display: none;
      background: rgba(0,0,0,0.6);
      width:100%;
      height:150px;
      text-align: center;

      h5 {
        color:white;
        font-size:24px;
      }
    }

    &:hover {
      cursor: pointer;
      cursor: hand;
      .item {
        display: table-cell;
      }
    }

  }
}
.campaign-add-item {
  height:150px;
  margin-bottom:20px;
  background: white;
  display: table;
  width:100%;

  .inner-item {
    display: table-cell;
    width:100%;
    height:150px;
    text-align: center;

    a {
      color:$btn-info-bg;
      font-size:58px;
      text-decoration: none;
      display: block;
      line-height:150px;
    }
  }
}

#angGalleryLs {
  .popupBackgroundPositions {

    .image-holder {
      ul {
        li {
          display: block;

          .options {
            display: none;
            position: absolute;
            top:0;
            bottom: 20px;
            left: 15px;
            right: 15px;
            margin:auto;
            background:rgba(0,0,0,0.7);

            .inner-options {
              text-align:center;
            }
          }
          &:hover {
            .options {
              display: block;
            }
          }
        }
      }
    }

    .tab-menu .imageGal {
      li {
        display: block;
      }
    }
    .ang_current_background, .ang_current_image {
      width:150px;
      height:150px;
      border:1px solid #CCCCCC;
    }
    .position-options {
      max-width:150px;
    }
  }
  .tabs {
    .tab {
      ul.tab-items {
        margin:0px;
        padding:0px;

        li {
          display:block;

          .options {
            display: none;
            position: absolute;
            top: 0;
            bottom: 20px;
            left: 15px;
            right: 15px;
            margin: auto;
            background: rgba(0, 0, 0, 0.7);

            .inner-options {
              text-align:center;
            }
          }

          &:hover {
            .options {
              display: block;
            }
          }
        }
      }
    }
  }
}
.popupContainmentArea .editable-block {
  min-height:20px!important;

}

.flash-ad {

  position: absolute;
  top:150px;
  padding:20px;
  width:25%;
  right:0;
  min-width:400px;
  z-index:1900;
  display:none;
  margin:auto;
  .alert {
  }
}

.admin-pagecreator-published {
  .abOptions {
    margin-left: -10px;
    padding: 10px;
    width: 80px;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);


    li {
      display:block;
      clear:both;

      a {
        color:$dark-grey-nine;
      }
    }

  }
}


.admin-page_creator-new, .admin-page_creator-edit  {
  .individual-options {
    .options {
      color:white;
      display:none;
      .backNav {
        display: block;
        font-size:40px;
        font-weight: bold;
        padding:10px 0;
        i {
          color:white;
        }
      }
    }
  }
  .dropzone-wrapper {
    width:100%;
    height:150px;
    overflow-x:hidden;
    overflow-y: scroll;

    i {
      font-size:3.2vw;
    }
    .dz-success {
      display:none;
    }
    .dropzone.dz-started .dz-message {
      display:block;
    }
  }
  #imageScroller, #imageScrollerImage {
    width:100%;
    height:127px;
    padding:0px;
    overflow-x:scroll;
    overflow-y: hidden;

    .image-holder {
      .image-item-none {
        i {
          font-size:50px;
        }
        border:1px dashed $light-grey-two;
        text-align: center;
        width:100px;
        height:100px;
        padding:5px;
        margin:5px;
        line-height: 115px;
        cursor: hand;
        cursor: pointer;
        float:left;
        &:hover {
          i {
            color:$light-grey-one;
          }
        }
      }
      .image-item {
        border:1px dashed $light-grey-two;
        text-align: center;
        width:100px;
        height:100px;
        padding:5px;
        margin:5px;
        line-height: 90px;
        cursor: hand;
        cursor: pointer;
        float:left;
        &:hover {
          img {
            opacity: 0.5;
          }
        }
        img {
          max-height: 90px;
          width:auto;
        }
      }
    }
  }

  #pageContainer {
    padding: 0 0 0 50px;
  }
  .sidenav-page-creator-nav {
    colow:white;
    min-height:100vh;
    width:50px;
    max-width:350px;
    z-index:20000;
    top:0;
    position: absolute;
    background: $dark-grey-eight;
    .ls-pagecreator-nav {
      .single-template-options, .template-options {
        width:50px;
        text-align: center;
        line-height:50px;
        a {
          display: block;
          color:white;
          font-size:30px;

          &:hover {
            background: $dark-grey-seven;
          }
        }
      }
    }
  }

  .highlight {
    font-weight: bold;
    font-size: 45px;
    background-color: lightblue;
  }

  .active-options {
    border:2px dashed lawngreen;
  }
  .ls-page-container {
    max-width:100%;
    padding-top:50px;
    padding-bottom:50px;
    min-height:200px;
    margin:50px auto;
    position: relative;

    .container-options-section {
      border:1px dashed $dark-grey-one;
      position: absolute;
      z-index:100;
      //width:100%;
      //height:100%;
      top:-5px;
      bottom:-5px;
      left:-5px;
      right:-5px;

      margin:auto;
      .label-section {
        position: absolute;
        background-color: $dark-grey-one;
        color:white;
        padding:5px 10px;
        left:5px;
        top:5px;
        z-index:1500;
      }

      .options-list-section {
        position: absolute;
        background-color: $dark-grey-one;
        padding:5px;
        right:5px;
        top:5px;
        z-index:1500;

        a {
          padding:1px 10px;
          display: block;
          float:left;
          color:white;
          &:hover {
            text-decoration: none;
          }
        }
      }

      .add-section {
        z-index:1500;
        width:20px;
        height:20px;
        background: $dark-grey-one;
        text-align: center;
        line-height: 20px;
        border-radius: 100%;
        position:absolute;
        left:0;
        right:0;
        bottom:5px;
        margin:auto;

        .add-section-btn {
          border-radius: 100%;
          font-size:20px;
          color:white;
          display:block;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .ls-block {
      display: block;
      position: relative;
      z-index: 500;

      .ls-block {
        z-index:600;
      }
      .options-section {
        visibility: hidden;
        border:1px dashed $dark-grey-one;
        position: absolute;
        z-index:30;
        top:-5px;
        bottom:-5px;
        left:-5px;
        right:-5px;
        margin:auto;

        .label-section {
          position: absolute;
          background-color: $dark-grey-one;
          color:white;
          padding:5px 10px;
          left:5px;
          top:5px;
        }
        .options-list-section {
          position: absolute;
          background-color: $dark-grey-one;
          padding:5px;
          right:5px;
          top:5px;
          a {
            padding:1px 10px;
            display: block;
            float:left;
            color:white;
            &:hover {
              text-decoration: none;
            }
          }
        }

        .add-section {
          width:20px;
          height:20px;
          background: $dark-grey-one;
          text-align: center;
          line-height: 20px;
          border-radius: 100%;
          position:absolute;
          z-index:1200;
          left:0;
          right:0;
          bottom:5px;
          margin:auto;

          .add-section-btn {
            border-radius: 100%;
            font-size:20px;
            color:white;
            display:block;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .active-options {
      border:none;
      .content-block {
        position: relative;
      }
      .active-options {
        z-index:1900;
      }
    }
    #activeCkEle {
      z-index:1300 !important;
    }
    .active-options > .options-section:first-child {
      border:2px dashed lawngreen !important;
      visibility: visible!important;
    }
  }

  .ls-page-container.active-options {
    .label-section {
      top:-30px;
    }
    .options-list-section {
      top:-30px;
    }
    .add-section {
      bottom:-30px;
    }
  }

}
.admin-pages, .shopify-leadslidesdk-admin-pages-dashboard {
  #myTabContent {
    padding-top:40px;
  }
  .supportPopupForm {

    .modal-header {
      border:none;
      padding-bottom:0px;
      h4 {
        padding-left:28px;
        padding-right:28px;
        color: $dark-grey-two;
        font-weight: 200;
        font-size:26px;
        margin:10px 0;
      }
    }
    .submitfield {
      padding:15px 10px 0 0;
    }
    .modal-footer {
      padding:10px;
      margin:0;
      border:0;
    }

    label {
      color: $light-grey-four;
    }
  }



  .error-list {
    margin:10px auto;
  }

  .filter-wrapper {
    margin-bottom:20px;
  }
  .btn.col-md-12 {
    width:100%;
  }
  ul.leadslide-service {
    padding:0;
    margin:0;
    li {
      margin-bottom:2em;
      display:block;
      .block-wrapper {
        background: white;
        padding:10px;
        border:1px solid transparent;
        -webkit-box-shadow:0px 2px 3px 1px $light-grey-one;
        -moz-box-shadow:0px 2px 3px 1px $light-grey-one;
        box-shadow:0px 2px 3px 1px $light-grey-one;
        //background: $dark-grey-four;
        min-height:140px;

        .info-box {
          padding:20px 15px;
          font-size:18px;
        }
        .image-block {
          background: $dark-grey-one;
          min-height:250px;
          text-align: center;
          position: relative;

          .desc-block {
            opacity: 0;
            position: absolute;
            top:0;
            right:0;
            left:0;
            bottom:0;
            margin:auto;

            a {
              position: absolute;
              margin:auto;
              padding-top:25%;
              top: 0;
              bottom:0;
              left:0;
              right:0;

              font-size:24px;
              color:white;
              text-decoration: none;

            }
          }

          img {
            //width:75%;
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;

            height:auto;
            max-height:100%!important;
            margin:auto;
          }

        }
        &:hover {
          .image-block {
            img {
              opacity: 0.8;
            }
          }
          .desc-block {
            &:before {
              position: absolute;
              left:0;
              right:0;
              top:0;
              bottom:0;
              content:'';
              background: black;
              opacity: 0.6;
            }
            h3 {
              color:white;
            }
            opacity: 1;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
          }
        }
        .text-block {
          h3 {
            margin:20px 0;
            padding:0;
          }
        }
        .action-block {
          margin-top:10px;
          width:100%;
          .btn {
            width:100% !important;
          }
        }
      }

      .block-wrapper.active {
        border:1px solid $btn-success-border;
      }
    }
  }

  .shopify-install-form {
    margin-top:20px;
  }
  //header sections sidebar and title areas
  .header-area {
    img {
      max-height:30px;
      width:auto;
    }
    h1 {
      font-size: 30px;
      line-height: 80px;
      margin: 0;
      padding: 0;
    }

    .admin-links {
      margin:0;
      padding:0;
      li {
        display:block;
        float:right;
        margin:5px;
        line-height: 70px;
        a {

        }
      }

    }
  }

  //content area
  .admin-content {
    padding-bottom:40px;

      .header-area {
        background: white;
        margin-left:-15px;
        margin-right:-15px;
        padding-left:15px;
        margin-bottom:50px;
      }
      //background: $dark-grey-five;
      background: $light-grey-three;
      min-height: 107vh;//TODO: change ipad and mobile
  }
  .admin-outer-wrapper {
    overflow: hidden;

  }
  //sidebar
  .admin-sidebar {
    padding:0 0 9000px 0;
    height:100%;
    margin-bottom:-9000px;
    background: $dark-grey-three;

    .header-area {
      h1 {
        background: black;
        a {
          padding-left:40px;
        }
      }
    }
    .navbar {

      background: $dark-grey-three;

      .navbar-collapse {
        padding:0;
      }

      .accountSwitcher {
        margin:0;
        padding:0;
        li {
          display:block;
          a {
            position: relative;
            font-size: 18px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            display: block;
            padding: 20px 50px;
            color: #8a949f;

            &:hover {
              background: $dark-grey-ten;
              color: white;
            }

          }


        }
        li.currentUser {
          a {
            background: $dark-grey-ten;
            color: white;
          }
        }

        .otherAccounts {
          display: none;
          padding:0;
          margin:0;
          background: $dark-grey-ten;
          max-height:450px;
          overflow: auto;

          li {
            text-align: center;
          }
        }

      }

      .sidebar-options {
        padding: 50px 0 0 0;
        width: 100%;

        min-height: 100vh; //TODO: Change for mobile and ipad sizes
        li {
          display: block;
          clear: both;
          width: 100%;


          a {
            position: relative;
            i {

              margin-right: 15px;
            }

            font-size: 18px;
            font-family: $font-family-base;
            display: block;
            padding: 20px 50px;
            color: $dark-grey-two;
            &:hover {
              background: $dark-grey-ten;
              color: white;
            }
          }

          a.active {
            background: $dark-grey-four;
            &:hover {
              background: $dark-grey-ten;
            }
          }

          ul {
            display:none;

            width:80%;
            height:auto;
            background: $dark-grey-four;
            position: absolute;
            right:-80.05%;
            top:0;
            z-index:200;
            padding:0px;
            margin:0;

            &:before {
              position: absolute;
              content:'';
              left:-16px;
              top:12px;
              z-index:199;
              width:16px;
              height: 0px;
              border-style: solid;
              border-width: 16px 16px 16px 0px;
              border-color: transparent $dark-grey-four transparent transparent;
            }

            li {
              a {
                padding:20px 30px;
                &:hover {
                  background: $dark-grey-three;
                  color:white;
                  text-decoration: none;
                }
              }
              a.active {
                background: $dark-grey-four;
                &:hover {
                  background: $dark-grey-ten;
                }
              }
            }
          }

          @media screen and (max-width: 768px) {
            ul {
              width:100%;
              position: relative;
              background: $dark-grey-four;
              right:0;
              left:0;
              z-index:200;
              padding:0px;
              margin:0;

              li {
                a {
                  padding-left:10%;
                }

              }
            }
          }
        }
      }
    }
  }
  .shopify-menu-leadslide {
    .sidebar-options {
      padding: 0px 0;
      width: 100%;
      display: table;
      //min-height: 50px; //TODO: Change for mobile and ipad sizes
      li {
        display: table-cell;
        clear: none;
        width: auto;
        float: none;
        text-align: center;
        position: relative;
        a {
          display: block;
          text-align: center;
          position: relative;
          i {

            margin-right: 0px;
          }

          font-size: 24px;
          font-family: $font-family-base;
          display: block;
          padding: 0px;
          line-height:62px;
          color: $dark-grey-two;
          &:hover {
            background: $dark-grey-ten;
            color: white;
          }
        }

        a.active {
          background: $dark-grey-four;
          &:hover {
            background: $dark-grey-ten;
          }
        }

        ul {
          display:none;

          width:220px;
          height:auto;
          background: $dark-grey-four;
          position: absolute;
          right:0;
          left:0;
          top:62px;
          z-index:200;
          padding:0px;
          margin:0;

          &:before {
            display: none;
          }

          li {
            display: block;
            width:100%;
            clear:both;
            text-align:left!important;

            a {
              i {
                margin-right:10px;
              }
              padding:12px 20px;
              font-size: 17.5px;
              line-height:24px;
              text-align:left!important;

              &:hover {
                background: $dark-grey-three;
                color:white;
                text-decoration: none;
              }
            }
            a.active {
              background: $dark-grey-four;
              &:hover {
                background: $dark-grey-ten;
              }
            }
          }
          li:first-child {
            a {
              padding-top:35px;
            }
          }
          li:last-child {
            a {
              padding-bottom:35px;
            }
          }
        }

        @media screen and (max-width: 768px) {
          ul {
            width:100%;
            position: relative;
            background: $dark-grey-four;
            right:0;
            left:0;
            z-index:200;
            padding:0px;
            margin:0;

            li {
              a {
                padding-left:10%;
              }

            }
          }
        }
      }
    }
  }
 }


//individual pages
//services page
.admin-services_index, .admin-plugins-index {
  .leadslide-service {
    li {
      .image-block {
        background: $light-grey-three;
      }
    }
  }
}

.admin-templates-custom-index {
  ul.leadslide-service {
    .desc-block {
      a {
        padding-top:30% !important;
      }
    }
  }

}
//dashboard
.admin-index, .shopify-leadslidesdk-admin-pages-dashboard, .wordpress-sdk-admin-dashboard {

  .admin-checklist {
    margin:0;
    padding:0;
    li {

      h3 {
        font-size:18px;
      }
      display: block;

      .sub-check {
        padding-left:20px;
        li {
          display:block;
        }
      }
      a {
        display:block;
        font-size:16px;
        color:$dark-grey-one;
        &:hover {
          color:$dark-grey-two;
          text-decoration: none;
        }
      }
    }
    li.checked {
      a {
        text-decoration: line-through;
        i {
          color: $valid-color;
        }
      }
    }
    li.unchecked {
      a {
        i {
          color: $invalid-color;
        }
      }
    }
  }
}
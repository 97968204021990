
$content_background: none;
$footer_background: none;
$navbar_background:$dark-grey-eight;
$home_page_body_background: url('/images/pages/themes/plain/homepage-shopify-prebetaheadover-bgimage.png');
$alt_page_background: white;
$title_colors: white;

$copy_notice_p_color:white;
$navbar_link_color:white;
$title_font_family: 'Helvetica', 'Arial', 'san-serif';
$footer_background_alt_pages: $dark-grey-eight;

//Plain Theme By Martyn Hill
.theme-plain {
  background-image:$home_page_body_background;
  background-color: $navbar_background ;
  -webkit-background-size:cover;
  background-size:cover;
  background-size:100%;
  background-repeat: no-repeat;
  background-position: center center;


  #alreadHaveAccount {
    color:$dark-grey-four;
  }

  //navbar styles
  .navbar {
    background:$navbar_background;
    min-height:10px;
    height:auto !important;

    .navbar-brand {
      color:$navbar_link_color;
      line-height: 34px;
      padding:0;
      margin:0;
      img {
        max-width:200px;
        width:auto;
        margin:20px 0;
        padding:0;
      }
    }

    //default all
    a {
      padding:10px 5px;
    }

    li {
      a {
        line-height: 34px;
        margin:0;
        color:$navbar_link_color;
        padding:20px 10px;
        font-size:14px;
        text-transform:capitalize;
      }

      &:hover {
        a {
          color:$dark-grey-five;
          background:none;
          border-radius:0px;
        }
      }

      .dropdown-menu {
        li {
          text-align:center;

          a {
            color:$dark-grey-twelve !important;
           &:hover {

           }

          }


        }
      }
    }
  }
  //end navbar styles

  //main page content styles
  .outer-wrapper {
    //min-height:20px;
    //height:auto !important;
    background: $content_background;
    padding: 5% 0;
    min-height:85vh;

    //main title
    .main-title {
      h1 {
        color:$title_colors;
      }
    }

  }
  //end main page content styles

  //start footer styles
  .footer-container {
    background: $footer_background;
  }
  .main-footer {
    //border-top: 1px solid $dark-grey-four;
    background: $footer_background;
    padding:20px 0;

    .container {

      .footer-links {
        margin-bottom:0px;

        li {
          display:block;

          a {
            color:$navbar_link_color;
            font-size:16px;
            line-height:40px;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .social-links {

        li {
          background:black;
          float:left;
          margin:0px 5px;
          border-radius:100%;
          line-height:40px;
          height:40px;
          width:40px;
          text-align:center;

          a {
            color:white;
            display:block;
            margin:0px 5px;
            line-height:40px;
            font-size:20px;
          }

          &:hover {

            a {

            }
          }
        }



      }

      .copy-notice {
        img {
          margin:0px;
          line-height:40px;
          max-width:90px;
        }
        p {
          margin:0px 20px;
          line-height:30px;
          color:$copy_notice_p_color;
        }
      }
    }
  }
  //end footer styles

  .contact-page {
    background: $alt_page_background;
    h2 {
      font-size:2.3em;
    }
    p {
      margin-bottom:1.8em;
    }
  }

  .coming-soon {
    h1 {
      font-size:3.4em;
    }

    p {
      font-size:1.4em;
    }
    padding:20% 0;
  }



}

.pages-contact, .auth-login, .auth-register, .auth-password, .pages-about, .pages-terms {
  background: $alt_page_background;

  .outer-wrapper {
    min-height:90vh;
  }

  .footer-container {
    background:$footer_background_alt_pages;
  }
}
//about pages
.theme-plain.pages-about, .theme-plain.pages-terms {

  .outer-wrapper {
    padding:5% 0;
  }

  .section {
    padding-bottom: 40px;
    margin-bottom:40px;
    border-bottom:1px solid $dark-grey-five;
  }

  .port {
    text-align: center;
    margin-bottom:1.2em;
    img {
      max-height:150px;
      width:auto;
      max-width:100%;
      border-radius:100%;
    }
  }

  p {
    font-size:15px;
    line-height:26px;
    margin-bottom:2.3em;
  }

  h2 {
    font-size:3em;
    font-size:3vw;
    margin-bottom:1em;
  }



  .desc {
    .prof-title {
      text-align:center;
      margin-bottom: 2em;

      h3 {
        padding:0;
        font-size:19px;
        margin-top:0px;
        margin-bottom: 0.3em;
      }

      h3.reg {
        font-weight: normal;
        font-size:15px;
      }

    }
  }
}



//general all
@media only screen and (min-width : 200px) and (max-width : 1120px) {
  /* Styles */
  //home page
  .pages-index {
    background-size:cover;

    .theme-plain
    {
      .outer-wrapper {
        min-height:88vh;
      }
    }
  }
}

@media only screen and (min-width : 200px) and (max-width : 1000px) {
  //admin section
  .admin-pages {
    .admin-content {
      .header-area {
        margin-bottom:20px;
        .col-md-8 {
          float:left;
        }
        .col-md-4 {
          float:right;
        }
      }

      .leadslide-service {
        li {
          width:49%;
          float:left;

          .block-wrapper {
            width:100%;
            .action-block {
              display:table;
              .col-md-4 {
                display:table-cell;
              }
            }
          }
        }
      }

      #pageBuilder {
        .col-md-4 {
          width:35%;
          float:left;
        }
        .col-md-1 {
          width:10%;
          float:left;
        }
        .col-md-7 {
          width:55%;
          float:left;
        }
      }

    }
  }
}
@media only screen and (min-width : 200px) and (max-width : 768px) {
  .admin-pages {
    .admin-content {
      .leadslide-service {
        li {
          width:100%;
        }
      }
    }

  }
}
@media only screen and (min-width : 200px) and (max-width : 430px) {
  .admin-pages {
    .admin-content {
      .header-area {
        margin-bottom:20px;
        .col-md-8 {
          width:100%;
          text-align: center;
          float: none;
        }
        .col-md-4 {
          display:none;
        }
      }
    }

    .admin-sidebar {
      width:15vw;
    }

    .admin-content {
      width:85vw;
    }
  }
}


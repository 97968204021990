.pages-terms {
  p {
    font-size:20px !important;
    line-height:34px!important;
    margin-bottom:1.3em!important;
  }
}
.shopify-apps-container {
  margin:40px auto;
}

.text-replace {
  text-indent:-999999999px;
}
.pages-about, .pages-contact, .pages-subscribe, .auth-password, .auth-login, .auth-register, .home-page, .standardTheme {
  //defaults
  h1, h2, h3, h4, h5, h6,p, a, li {
    font-family:"PT Sans", helvetica, arial, sans-serif;
  }

  ol {
    margin:40px 0;

    li {
      font-size:24px;
      line-height:36px;
      margin:5px 0;
    }
  }

  p, address {
    font-size:24px;
    line-height:36px;
    margin-bottom:1.3em;
  }

  h2 {
    text-transform: uppercase;
    color:#31373d;
    font-size:36px;
    line-height:50px;
    margin:0 0 40px 0;
  }

  h3 {
    text-transform: uppercase;
    color:#31373d;
    font-size:30px;
    line-height:45px;
    margin:0 0 0 0;
  }
}

.theme-launch, .shopifyLeadSlide {
  .margin-bottom-lg {
    margin-bottom:80px;
  }

  .margin-top-lg {
    margin-top:80px;
  }
  .smtabs-page {
    #fontList {
      padding:0;
      margin:0;
      li {
        padding:0;
        margin:0;
        display: block;
        text-align: center;

        .remove {
          display:none;
        }

        a {
          text-align:center;
          color:$dark-grey-twelve;
          text-decoration: none;
          display: block;
          padding-top:10px;
          padding-bottom:10px;
          font-size:50px;
          color:black;
        }
      }
    }

    .options {
      margin:40px 0;
      .opts {

        .change-color {
          padding: 5px 5px 7px 35px;
          min-width: 120px;

        }
        .mr20 {
          margin-right:20px;
        }
        #bWidth {
          max-width:50px!important;
        }
        label {
          font-weight: bold;
        }
        .radio-inline, .checkbox-inline {
          padding-left:0;
          padding-right:20px;
        }

        .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"]
        {
          top:0;
          right:0;

        }
      }

      .preview {

        position: relative;
        width:100%;
        min-height:450px;
        border:3px dashed $dark-grey-twelve;
        background: $light-grey-six;

        #leadslideMediaTab {
          position: absolute;
          width:80px;
          height:auto;
          top:70px;
          padding:0px;

          li {
            display:block;
            clear:both;
            text-align: center;
            background:black;
            position: relative;
            a {
              line-height:80px;
              display: block;
              text-align:center;
              color:white;
              font-size:28px;

            }

            .remove {
              position: absolute;
              top:5px;
              right:5px;
              color:$light-grey-six;
              cursor: hand;
              cursor: pointer;

              &:hover {
                opacity:0.8;
              }
            }

          }
        }
      }
    }

    #leadslideMediaTabsStructure {
      padding:0px;
      margin:0px;

      li {
        display:block;
        clear:both;

        i {
          font-size: 24px;
          min-width: 30px;
          margin-right: 10px;
          text-align: center;
        }
      }
    }
  }

  .shopify-apps-container {
    p {
      font-size:18px;
      line-height:28px;
      margin-bottom:1.3em;
    }

    ol {
      li {
        font-size:18px;
        line-height:28px;
        margin-bottom:.5em;
      }
    }

    h3 {
      font-size:24px;
    }
  }


  .main-navbar-ls .navbar {
    background:$navbar_background;
    min-height:10px;
    height:auto !important;

    .navbar-brand {
      color:$navbar_link_color;
      line-height: 34px;
      padding:0;
      margin:0;
      img {
        max-width:200px;
        width:auto;
        margin:20px 0;
        padding:0;
      }
    }

    //default all
    a {
      padding:10px 5px;
    }

    li {
      a {
        line-height: 34px;
        margin:0;
        color:$navbar_link_color;
        padding:20px 10px;
        font-size:14px;
        text-transform:capitalize;
      }

      &:hover {
        a {
          color:$dark-grey-five;
          background:none;
          border-radius:0px;
        }
      }

      .dropdown-menu {
        background: url('../images/overlay-two.png')!important;
        li {
          text-align:center;

          a {
            color:white !important;
            &:hover {

            }

          }


        }
      }
    }
  }




  //pages
  .home-page {
    .btn {
      border-radius:10px;
      border-color: transparent;
      font-size:24px;
      padding:10px 25px;
    }

    .navbar-default {
      background:none !important;

      .navbar-nav {
        li {
          a {
            color:white;
            font-size:15px;
            line-height:15px;
            display:block;
            padding:8px 15px;
            border:1px solid transparent;
            text-shadow: none;
            &:hover {
              background:none;
              border-radius:40px;
            }
          }

          .dropdown-menu {
            background: url('../images/overlay-two.png');
            width:100%;
            min-width:1px;
            box-shadow:none;
            border:none;

            li {
              text-align:center;
              a {
                &:hover {
                  border-color: transparent;
                }
              }
            }
          }
        }
      }
      .navbar-brand {
        display:block;
        img {
          margin:0!important;
          max-height:33px;
        }
      }
    }

    .container {
      position: relative;
    }
    .main-header {
      padding-top:20px;
      background-image:url('../images/pages/themes/launch/banner.png');
      background-attachment: fixed;
      -webkit-background-size:cover;
      background-size:cover;
      position: relative;

      .heading {
        margin-top:120px;
        h1 {
          color:white;
          text-transform: uppercase;
          font-size:50px;
          font-weight:400;
          margin-bottom: 35px;
        }
      }

      .sub-heading {
        p {
          color:white;
          font-size:30px;
          margin-bottom:0px;
          font-weight:400;
        }
        margin-bottom:50px;
      }

      .button-blocks {

        margin-bottom:100px;

        .circle {
          border:5px solid white;
          color:white;
          border-radius: 100%;
          width:150px;
          height:150px;
          margin:auto;

          .inner
          {
            padding-top:42px;
            width:100%;
            height:100%;
            font-size:22px;
            color:white;
            text-align:center;
            text-transform: uppercase;
            font-family:open sans, helvetica, arial, sans-serif;

            i {
              margin-top:5px;
              font-size:40px;
            }
          }

        }
      }

      .button-holder {
        position: absolute;
        bottom:-35px;
        left:0;
        right:0;
        margin:auto;

        a {
          text-transform: uppercase;
          font-size:30px;
          padding:15px 0;
          border-radius:10px;
          border-color: transparent;
        }
      }
    }

    .sponsor-block {
      padding-top:45px;
      padding-bottom:10px;
      background-color:#edecec;

      .col-md-3 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align:center;
        height:70px;
      }
    }

    .text-image-right-block, .text-image-left-block {
      padding-top:90px;
      padding-bottom:90px;
      background-color:white;

      p {
        color:#666666;
        font-size:24px;
        line-height:36px;

      }
    }

    .text-image-left-block {
      background:#edecec;
      padding-bottom:20px;

      img {margin-bottom:-20px;}

      h2 {

      }
      ul {
        margin:0px 0 40px 0;
        padding:0px;

        li {
          display:block;
          color:#666666;
          font-size:20px;
          margin-bottom:10px;
        }
      }
    }

    .text-image-block {
      background:#edecec;
      padding-top:90px;
      padding-bottom:90px;

      .title {
        .launch-green {
          color:#35dbb2;

        }

        p {
          color:#666666;
          font-size:24px;
          line-height:36px;

        }

        margin-bottom:60px;
      }


    }

    .button-text-block {
      padding:40px 0;
      background:#31373d;
      color:white;

      .title {
        h2 {
          margin-top:0px;
          margin-bottom:20px;
          color:white!important;
        }
        margin-bottom:20px;
      }

      .button-holder {
        margin-bottom: 20px;

        .btn {
          font-size:36px;
          padding:10px 30px;
          border-radius:10px;
        }
      }
    }





  }

  .reg-font {
    font-weight:400;
  }


  //other pages

  .contact-page {
    background: $alt_page_background;
    h2 {
      font-size:2.3em;
    }
    p {
      margin-bottom:1.8em;
    }
  }

  .coming-soon {
    h1 {
      font-size:3.4em;
    }

    p {
      font-size:1.4em;
    }
    padding:20% 0;
  }
  .pages-contact, .auth-login, .auth-register, .auth-password, .pages-about, .pages-terms {
    background: $alt_page_background;

    .outer-wrapper {
      min-height:90vh;
    }

    .footer-container {
      background:$footer_background_alt_pages;
    }
  }
  //about pages
  .pages-about, .pages-terms {

    .outer-wrapper {
      padding:5% 0;
    }

    .section {
      padding-bottom: 40px;
      margin-bottom:40px;
      border-bottom:1px solid $dark-grey-five;
    }

    .port {
      text-align: center;
      margin-bottom:1.2em;
      img {
        max-height:150px;
        width:auto;
        max-width:100%;
        border-radius:100%;
      }
    }



  }
  .footer-bottom {
    padding:20px 0;
    background-color:#edecec;

    .footer-nav {
      margin:0px;
      padding:0px;

      li {
        display:block;
        float:left;
        color:#666;

        a {
          padding-left:15px;
          padding-right:15px;

          color:#666;

          &:hover {
            text-decoration: none;
            opacity:0.9;
          }
        }
      }
    }
  }

  //overriding defaults
  .padding-bottom-none {
    padding-bottom:0px !important;
  }

  .fixed-navigation.navbar {
    background:$navbar_background!important;
    min-height:10px;
    height:auto !important;
    position: fixed;
    width:100%;
    left:0;
    right:0;
    z-index: 900;
    top: 0;
    padding: 20px;

    .navbar-brand {
      color:$navbar_link_color;
      display:block;
      padding:0!important;
      img {
        max-width:200px;
        width:auto;
        margin:0!important;
        padding:0;
        max-height:33px;
      }
    }

    //default all
    a {
      padding:10px 5px;
    }

    li {
      a {
        color:$navbar_link_color;
        font-size:15px;
        line-height:15px;
        display:block;
        padding:8px 15px;
        border:1px solid transparent;
        text-shadow: none;
      }

      a.btn {
        border-radius:5px!important;
        &:hover {
          background: #289e7e!important;
          border-radius:5px;
          border-color:#289e7e!important;

        }
      }





      .dropdown-menu {
        li {
          text-align:center;

          a {
            color:$dark-grey-twelve !important;
            &:hover {

            }

          }


        }
      }
    }
  }

}

a {
  transition: all 10ms ease !important;
  &:hover {
    transition: all 10ms ease !important;
  }
}

.pages-subscribe {
  height:100%;
  background: url('../images/sub-back.png') repeat-x;
  background-size:100% 50%;

  .navbar-nav {
    display:none!important;
    position: relative!important;
  }

  .outer-wrapper-launch {
    .container {
      padding-top:70px!important;
      padding-bottom:70px!important;
    }
  }
  .header-section-m {
    margin-bottom:0px;


    h1 {
      color:white;
      font-size:48px;
      line-height:default;
      text-transform: uppercase;
    }

    h2 {
      color:white;
      font-size:34px;
      line-height:default;
      margin-bottom:30px;
      text-transform: capitalize;
    }

    p {
      color:white;
      font-size:24px;
      margin-bottom:20px;

      small {
        font-style: italic;
        font-weight:200;
        font-size:18px!important;
      }
    }

    a.green {
      color:#48cfae;
      text-decoration: underline;
    }
  }

  .select-options {
    margin-bottom:45px;
    ul {
      width:280px;
      margin:0 auto;

      li {
        display:block;
        float:left;
        font-size:24px;
        line-height:42px;
        padding:5px 20px;
        color:white;

        &:hover {
          opacity: 0.9;
          cursor: pointer;
          cursor: mouse;
        }
      }

      li.active {
        color:#48cfae;
        border-bottom:4px solid #48cfae;
      }
    }
  }
  .payment-wrapper {

    .membership {
      background:white;
      border-radius:5px;
      border-radius: 10px;
      padding-bottom:20px;
      margin-bottom:20px;

      &:before {
        position: absolute;
        height:613px;
        top:163px;
        content:'';
        z-index:-1;
        margin:auto;
        left:15px;
        right:15px;

        box-shadow: 0px 0px 40px 0px #bfbfbf;
        border-radius: 0 0 10px 10px;
      }


      .header {
        text-align:center;
        background: $dark-grey-one;
        color:white;
        padding:5px 0;
        border-radius:10px 10px 0 0;

        h3 {
          font-size:30px;
          font-weight:400;
          line-height:18px;
          color:white;
          margin:20px 0 10px 0;
        }

        p {
          font-size:14px;
          line-height:18px;
        }
      }

      .membership-includes {
        padding:0px 10px
      }
      ul {
        width:100%;
        margin:0;
        padding:0;
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-radius: 0 0 10px 10px;
        li {
          line-height:55px!important;
          height: 55px;
          text-align:center;
          font-size:14px;
          line-height:18px;
          color:#444444;
          background:#f2f2f2;
          border-bottom: 1px solid #e4e4e4;
          width:100%;
          display:block;
        }
      }

      .price {
        font-size:60px;
        color:#444444;
        font-weight:bold;
        margin-top:5px;

        sup {
          vertical-align: middle;
          margin-right:-10px;
          font-size:25px;
          font-weight: bold;
        }

        .currency {
          font-size:25px;
          margin-left:-10px;
          font-weight: 400;
        }



      }

      .option {
        font-size:14px;
        margin-bottom:20px;
        margin-top:-15px;
      }

      .btn {
        font-size:24px;
        padding:12px 0;
        border-color: transparent;
        width:100%!important;
      }
    }

    .membership.basic {
      .header {
        background:#48cfae;
      }
    }

    .membership.business {
      span.business
      {
        color:#b52259;
      }
      .header {
        background:#b52259;
      }
    }

    .membership.agency {
      span.agency
      {
        color:#f63671;
      }
      .header {
        background:#f63671;
      }
    }
  }


}

.pages-whysubscribe, .pages-about {
  .content {
    padding:0px 45px;
    p {
      font-size:20px;
      margin-bottom:1.3em;
    }

    h3 {
      margin-bottom:20px;
      margin-top:60px;
    }
    ul li {
      font-size:20px;
      margin-bottom:1.3em;
    }

    .btn {
      font-size:24px;
      padding:15px 60px;
      border-color: transparent;
    }
  }

}

@media screen and (max-width : 991px) {
  .pages-subscribe  {
    background-size: 100% 18.2%;

    .payment-wrapper {
      .membership {
        &:before {
          display: none;
        }
        box-shadow: 0px 0px 10px 0px #bfbfbf;
      }
    }
  }
}
.admin-tickets-view {

  .supportTicket {

    .basicThemeBlocks {
      position: relative;

      .lastEdit {
        width:200px;
        position: absolute;
        bottom:10px;
        right:0px;
      }

    }

  }
  .avatar {
    margin-right:20px;
    max-width:150px;
    float:left;
    small {
      font-size:10px;
    }

    .editOpts {
      ul {
        width:100%;
        margin:0;
        padding:0;

        li {
          display:block;
          float:left;
          padding:2px;
        }
      }
    }
    .replyname {
      font-weight:bold;
      text-transform: capitalize;
      text-align:center;
    }

  }

}

.admin-support-index {
  .basicThemeBlocks {

    a {
      color: $dark-grey-eight;
      font-size:16px;

    }

    .col-md-3 {

      a {
        font-size:28px;
        text-decoration: none;
        margin:0px 5px;

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }

    }

  }
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.modal {
  z-index:3000;
}

.campaign_step {
  h3 {
    font-size:28px;
  }
  .check-list {
    padding:0px;
    margin:0px;

    li {
      display:block;
      color:$dark-grey-twelve;
      font-size:18px;
      margin-bottom:5px;

      .checked {
        margin-left:20px;
        font-size:28px;
      }
    }

    li.success {
      text-decoration: line-through;
      opacity:.7;
      .fa {
        color: #49ba5d;
      }
    }

    li.error {
      .fa {
        color: #ea575f;
      }
    }
  }
}


.absolute-100 {
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  width: 100%;
  height:100%;
  margin:auto;
  z-index:900;
}

.product_search {
  padding:15px;
  background:white;
  .close_opts {
    color:$dark-grey-twelve;
    font-size:14px;
    position: absolute;
    width:20px;
    height:20px;
    line-height:20px;
    top:5px;
    right:5px;

    &:hover {
      font-size:15px;
      opacity: .8;
      cursor: pointer;
      cursor: hand;
    }
  }

  .no-padding {
    padding:0px;
  }

  .search-bar {
    .ls-btn {
      border-radius:0 5px 5px 0;
    }
  }

  .product-placement {
    padding:20px;

    ul {
      margin:0px;
      padding:0px;
      max-height:320px;
      overflow: auto;

      li {
        display: block;
      }


      .single-product {
        .item {
          width:100%;
          height:150px;
          margin-bottom:15px;
          background-size:cover;
          .options {
            text-align:center;
            display: none;
            background:rgba(0, 0, 0, 0.6);
            height:150px;

             a {
               line-height:150px;
               color:white;
               display: block;
             }
          }

          &:hover {
            .options {
              display: block;
            }
          }
        }


      }
    }
  }
}

.innerOptionsCL{
  .col-xs-11 {
    padding:15px 15px 15px 15px;
  }
}

.campaign-home-options {
  position: relative;

  .show-options-trigger {
    font-size:40px;
    color:$dark-grey-two;
    &:hover {
      color: $dark-grey-nine;
    }
  }


}
.campaign-options {
  width:200px;
  position: absolute;
  top:100%;
  right:0;
  background:white;
  border: 1px solid $dark-grey-two;
  z-index:150;
  margin:0;
  padding:0 20px;

  li {
    display: block;
    width:100%;
    text-align:left;
    color:$dark-grey-two;
    font-size:16px;
    padding:5px;

    &:hover {
      cursor: pointer;
      cursor: hand;
      color: $dark-grey-nine;
    }

  }
}


.force-max-width {
  width:100%!important;

  .nav-block {
    width:100%!important;
  }
  .leadslide-center-menu {
    margin:0!important;
    padding:0!important;
    display: table!important;
    width:100%!important;

    li {
      display: table-cell!important;
      text-align: center!important;
      float:none!important;

      a {
        display: block!important;
        padding:5px 10px;
      }
    }
  }

}


.mail-service-nav-loader {
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  background:rgba(0,0,0,.8);
  z-index:9000;
  display:none;


  .inner-loader {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:250px;
    text-align:center;
    height:50px;
    line-height:50px;
    font-size:24px;
    margin:auto;
    color:white;
  }
}
.admin-admin-pages-soundest {
  p {
    font-size:16px;
    margin-bottom:1.3em;
  }
  .list-unstyled {
    margin:10px 0 20px 0;
    padding-left:20px;
    li {
      margin-bottom:1.3em;
      margin-bottom:8px;
      font-size:16px;
    }

  }
}

.angular-pages {
  .angLoader {
    display: none;
    position: fixed;
    background: rgba(0,0,0,0.8);
    top:0;
    left:0;
    bottom:0;
    right:0;
    margin:auto;
    width:100%;
    height:100%;
    z-index:9000;
  }

  .show-more-gallery-items {
    margin-top:20px;
    width:100%;
    clear:both;

  }

  .video-holder-video {
    .single-video {
      margin-bottom:20px;
      height:200px;
      overflow: hidden;
      text-align:center;
      img {
        height:100%;
        width:auto;
      }



      .controls {
        background:rgba(0,0,0,0.7);
        position: absolute;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        margin:auto;
        padding-top:25%;
        text-align:center;
        display: none;


        button {
          vertical-align: middle;
        }
      }
      &:hover > .controls {
        display: block;
      }
    }
  }
  .image-holder-image
  {
    clear:both;
    margin-top:20px;

    .image {
      height:250px;
      overflow: hidden;
      margin-bottom:20px;
      text-align:center;

      .controls {
        background:rgba(0,0,0,0.7);
        position: absolute;
        top:0;
        bottom: 0;
        left:0;
        right:0;
        margin:auto;
        padding-top:25%;
        text-align:center;
        display: none;


        button {
          vertical-align: middle;
        }
      }
      img {
        width:100%;
        height:auto;
      }

      &:hover > .controls {
        display: block;
      }

    }
  }
  //main edit menus
  .leadslide-mendu-edit
  {
    margin:0px;
    madding:0px;
    background:#31373D;
    width:100%;

    .menu-options.main-options {
      margin:0px;
      padding:0px;
      background:#31373D;
      width:100%;

      li {
        display: inline-block;
        padding:0px 0px;

        a {
          font-size: 60px;
          line-height: 140px;
          display: block;
          color: #92a0b3;

          &:hover {
            text-decoration: none;
            color:white;
            background: #3d454d;
          }
        }
      }
    }
    .options {
      .option {
        a.closeOptions {
          font-size: 60px;
          line-height: 140px;
          display: block;
          color: #92a0b3;

          &:hover {
            text-decoration: none;
            color:white;
            background: #3d454d;
          }
        }
      }
    }
  }

  .gallery-holder {
    position: fixed;
    height:100%;
    width:100%;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    margin:auto;
    z-index:600;

    .gallery-image-holder {
      height:90vh;
      overflow-y: auto;
      background:white;
      position: absolute;
      width:90%;
      top:20px;
      left:0;
      right:0;
      bottom:0;
      margin:auto;
      border-radius:10px;
      padding:20px;

      .closeGalleryTop {
        width:20px;
        height:20px;
        line-height: 20px;
        text-align: center;

        position: absolute;
        z-index:4;
        top:5px;
        right:5px;

        &:hover {
          cursor: pointer;
          cursor: hand;
        }


      }
    }
  }

  .changePopupBackground {
    height: 120px;
    vertical-align: middle;
    display: table-cell;

    color: #92a0b3;;
    text-decoration: none;
    text-shadow: 0 0 1px #FFF;

  }

  .navbar-logo {
    background:black;
    height:80px;
    a {
      line-height:80px;
      img {
        vertical-align: middle;
        max-width:100%;
        height:auto;
      }
    }
  }

  .navbar-content {
    background:#f8f8f8;
  }

  .popup-preview {
    min-height:95vh;
    position: relative;
    z-index:0;
    content:'';
    background:url('../images/shopify/apps/upsells/example-site-two.png') center center;
    background-size:cover;
    width:100%;
    height:100%;
    margin:auto;

    .leadslide-popup-container {
      background:rgba(0,0,0,0.8);
      width:100%;
      height:100%;
      min-height:95vh;

      .leadslide-popup {
        position: absolute;
        width:90%;
        max-width:750px;
        background:white;
        min-height:150px;
        left:0;
        right:0;
        top:15%;
        margin:auto;

        .leadslide-close
        {

          &:hover {
            opacity:0.8;
            text-decoration: underline;
          }
        }
        .leadslide-close.topClose {
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 25px;
          line-height: 15px;

          &:hover {
            cursor: pointer;
            cursor: hand;
            text-decoration: none;
          }
        }


        .leadslide-popup-footer {
          clear:both;
          text-align:center;
        }

      }
    }
  }

  .bar-options {
    margin:0px;
    padding:0px;
    li {
      display: inline-block;
      padding: 0px 15px;
      color: #62729F;
      font-size: 34px;

      a {
        color:#92a0b3;
        font-size:30px;
        line-height:80px;
        display: block;
        transition: all 10ms ease !important
      }
    }
  }
}

.outer-block .col-md-3 .img-block, .outer-block .col-md-4 .img-block
{
  text-align:center;
  img {
    max-height:250px;
    width:auto;
    height: auto;
  }
}
.coming-soon-thankyou-ls-app {
  .upsell-instructions {
    p {
      font-size: 16px;
      line-height: 27px;
      margin-bottom:1.3em;
    }
  }

  #instalation {
    ol {
      li {
        font-size:16px;
        margin-bottom:15px;
      }
    }
  }
}
.coming-soon-ls-app {
  .tutorialMode {
    li {
      position: relative;
    }

    .tuts {

      display:block;
      background:rgba(0,0,0,0.7);
      color:white;
      font-size:15px;
      padding:5px;
      position: absolute;
      right:-250px;
      top:0;
      margin:auto;
      width:240px;
      height:auto;


      &:after {
        width: 0;
        height: 0;
        content:'';
        position: absolute;
        top:0;
        bottom:0;
        left:-10px;
        margin:auto;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right:10px solid rgba(0,0,0,0.7);
      }
    }
  }

  .advancedMode {
    .tuts {
      display:none;
    }
  }
}

.basicThemeBlocks {
  box-shadow: 0px 2px 3px 1px #C6C6C6;
  padding:20px;
  background: white;
  min-height:200px;
  margin-bottom:20px;

  h3 {
    margin-top:0 !important;
  }
}

.shadowContainer {
  background:white;
  box-shadow: 0px 2px 3px 1px #C6C6C6;
  padding:17px;
}

.downloads-wrapper {
  .fileManager {
    .categories {
      margin:0;
      padding:0;

      li {
        min-height:100px;
        margin-bottom:20px;
        display:block;

        .category {
          font-size:80px;
          margin-bottom:0px;
          display: block;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.editEmailTemplates {
  #popOptions {
    display:none;
    background:rgba(0,0,0,0.7);
    position: relative;

    //img
    #ibo {
      .image-preview-box {
        height: 100px;
        width:100px;
        position: relative;
        border:1px dotted $dark-grey-twelve;
      }

    }

    .option {
      display:none;
      background:#f8f8f8;
      width:100%;
      padding:0 0 10px 0;
      margin:auto;
      min-height:100px;
      position: relative;
      border-radius:10px;


      .inner-options {
        max-height:400px;
        overflow-y: auto;
      }
    }

    .option.active
    {
      display:block;
    }
  }

  .lsMailTable {
    max-width:600px;
    margin:auto;

    td {
      position: relative;
    }
  }
  .controls {
    margin-bottom:40px;
  }
  .remove {
    display: none;
  }

  .insertNewBlock {
    width:40px;
    position: absolute;
    height:40px;
    text-align:center;
    bottom:-20px;
    left:0;
    right:0;
    margin:auto;

  }
  .outer-options {
    position: absolute;
    left:-5px;
    width:160px;
    top:-5px;
    margin:auto;
    display:none;
  }
  .template-select {
    li {
      display:block;
      &:hover {
        cursor: pointer;
        cursor: hand;
      }
    }

    li.active {
      border:1px solid $btn-success-border;
    }
  }
}

.campaign-page {
  .stepContent {
    clear:both;
    width:100%;
    .controls {
      margin-top:20px;
      clear:both;
    }
    .select-dnd {
      .sType {
        display:none;
      }

      .sType.active {
        display:block;
      }
    }
    .campaignTypes {
      padding:0;
      margin:0;
      width:100%;
      li {
        display:block;


        &:hover {
          cursor: hand;
          cursor: pointer;
        }
      }
      li.col-xs-4 {
        min-height:230px;
        background:$dark-grey-twelve;
        color:white;
      }
      li.active {
        border:1px solid $btn-success-border;
      }
    }
  }

}

#leadSlideSteps {
  .stepCategories {
    padding: 0;
    margin: 0 auto 20px auto;
    width: 100%;
    max-width: 320px;
    li {
      display:block;
      text-align: center;
    }
    li.step {
      a {
        background:$dark-grey-eleven;
        display:inline-block;
        color:white;
        text-align: center;
        cursor: pointer;
        cursor: hand;

        width: 50px;
        height: 50px;
        border-radius: 100%;
        line-height: 50px;
        text-decoration: none;
      }
    }

    li.step.active {
      a {
        background:$brand-success;
      }
    }

  }

  .stepContent {
    display:none;
    clear:both;
    margin-top:20px;
  }

  .stepContent.active {
    display:block;
  }
}

.pagination-holder {

}

.admin-index, .shopify-leadslidesdk-admin-pages-dashboard, .shopify-leadslidesdk-admin-pages-dashboard, .wordpress-sdk-admin-dashboard {

  .gettingStarted {
    font-size:20px;
    margin-bottom: 10px;

    a {
      color:$btn-info-border;
    }
  }
  .image-block {
    background:none !important;

    a {
      font-size:2.3em;
      padding-top:20px;
      color:$dark-grey-seven;
      text-transform: uppercase;
      display:block;
      min-height:250px;
      width:100%;
      font-family:'PT Sans', Sans-Serif;

      &:hover {
        text-decoration: none;
        color:$btn-info-border;
      }

      img {
        margin-top:20px !important;
        position: relative !important;
        display: block;
      }
    }
  }
}


#infoList {
  li {
    display: block;
    margin-bottom:8px;

    a {
      font-size:18px;
      color:$dark-grey-eight;
    }

    .infoBox {
      display:none;
      padding:10px 20px;
    }
  }
}
//standard all templates
.admin-links {
  margin:0;
  padding:0;
  li {
    display:block;
    float:right;
    margin:5px;
    line-height: 70px;
    a {

    }
  }

}
.outer-wrapper {
  min-height: 85vh;
  padding:2% 0;
}

.navbar-default {
  background: $grey-one;
  border:none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  .navbar-brand {
    padding:0px;
    //line-height: 20px;
    float:left;
    margin:0;
  }
  li {
    a {
    }
  }
  .navbar-right {
    li {
      a {
        margin:20px 0;
        padding-top:0;
        padding-bottom:0;
        &:hover {
          border-radius: 20px;
          background: $green-one;
          color:white;
        }
      }
    }
  }
}

.footer-container {
  //padding:20px;
  background: $grey-one;
}

.bottom-copy-notice {
  p {
    line-height: 40px;
    margin:0;
    padding:15px 0;
  }
}

img {
  max-width: 100%;
  height:auto;
}

//home page styles
.pages-index {
  .navbar {
    background: white;
    .navbar-brand {
      margin:0;
      padding:0;
      line-height:40px;
      height:auto;
      img {
        margin:10px 0;//images cant have line-height so this is a fix
      }
    }
    li {
      a {
        line-height:30px;
        margin:20px 0;
        padding-top:0;
        padding-bottom:0;
      }
    }
  }

  #register {
    .form-control {
      height:auto;
      padding:12px;
      font-size: 18px;
      .btn {
        font-size:26px;
      }
    }
  }

  .marketing-outer {
    padding:5%;
    position: relative;

    .marketing {
      height:100%;
      font-family: "PT Sans", helvetica, arial, sans-serif;

      h1 {
        font-size:3.3vw;
        line-height: 50px;
      }

      .circle {
        padding:5%;
        font-size: 1.8vw;
        font-weight: 400;
        margin:10% auto 4% auto;
        width:150px;
        height:150px;
        border-radius: 100%;
        border:4px solid white;
        color:white;
        img {
          width:60%;
          margin-top:3%;
          height:auto;
        }
      }
    }

    .create-campaign-button {
      font-size: 2.2vw;
      width:50%;
      margin:auto;
      left:0;
      right:0;
      position: absolute;
      bottom:-6%;
    }

  }

  .integrations {
    padding:5%;

    ul {
      margin:0;
      padding:0;

      li {
        display: inline-block;
      }
    }
  }
  .outer-wrapper {
    background: $dark-grey-one;
    .container, .full-container {
      h1 {
        color:white;
        font-size:3vw;
        margin-bottom:20px;
      }
      .logo-icon {
        margin-bottom:20px;
      }
      .image-holder {
        margin:8% 0;
      }
      .btn-holder {
        .btn {
          width:100%;
          font-size:30px;
          border-radius:20px;
        }
      }
    }
  }
}

.admin-support-replies {
  .ticket-reply {
    width:100%;

    li {
      display: block;
      margin-bottom:1.3em;
      clear:both;
      background: white;
      padding:20px;
      box-shadow: 0px 2px 3px 1px $light-grey-two;

      .btn {
        width:100%;
        margin-top:20px;
      }
    }
  }
}

//login page
.auth-login  {
  .navbar-default {
    background: white;
    .navbar-brand {
      padding-top:20px;
      line-height:40px;
    }
    li {
      a {
        line-height: 30px;
        margin:20px 0;
        padding-top:0;
        padding-bottom:0;
      }
    }
  }
  .outer-wrapper {
    padding:10% 0;
    min-height: 85vh;
    background: $grey-one;
    
    .auth-form {
      background: white;
      width:100%;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      padding:2%;
    }
  }
}

//register page
.auth-register  {
  .form-horizontal {
    .control-label {
      text-align:left;
    }
  }
  .navbar-default {
    background: white;
    .navbar-brand {
      padding-top:20px;
      line-height:40px;
    }
    li {
      a {
        line-height: 30px;
        margin:20px 0;
        padding-top:0;
        padding-bottom:0;
      }
    }
  }

  .outer-wrapper {
    padding:10% 0;
    min-height: 85vh;
    background: $grey-one;

    .auth-form {
      background: white;
      width:100%;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      padding:2%;
    }

  }
}

.auth-password  {
  .navbar-default {
    background: white;
    .navbar-brand {
      padding-top:20px;
      line-height:40px;
    }
    li {
      a {
        line-height: 30px;
        margin:20px 0;
        padding-top:0;
        padding-bottom:0;
      }
    }
  }
}

.admin-pages {
  background: $dark-grey-five;

  .url_link_select_list, .icon_select_list, .block_select_list {
    margin:0px;
    margin:0px;
    li {
      display:block;

      .options {
        display: none;
        width:100%;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        text-align:center;
        background:rgba(0,0,0,0.7);

        div {
          display: table-cell;
          width:100%;
          height:150px;
          vertical-align: middle;
          a {
            color:white;
            font-size:18px;

            &:hover {
              text-decoration: none;
              opacity:0.9;
            }
          }
        }

      }

      &:hover {
        .options {
          display: table;
          width:100%;
          height:150px;
        }
      }

    }
  }


  .popUpBT {
    position: fixed;
    z-index:900;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    background: url('../images/transback.png') repeat;

    .popUpContainment {
      position:absolute;
      margin:auto;
      //top:0;
      //bottom:0;
      //left:0;
      //right:0;
    }

  }

  .jqueryLoader {
    display:none;
    position: absolute;
    color:white;
    background: black;
    opacity: 0.7;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    height:100%;
    width:100%;
    z-index:1300;

    .spinner-block {
      width:50px;
      height:50px;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      text-align: center;
      position: fixed;

      i {
        color:white;
        font-size:50px;
      }
    }
  }
  .overLayModel {
    display: none;
    background:black;
    opacity: 0.7;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    margin:auto;
    z-index: 9000000;
  }

  #galleryModel {
    position: fixed;
    background: white;
    padding:3%;
    height:auto;
    width:95%;
    top:2.5%;
    left:2.5%;
    margin-bottom:2.5%;
    border-radius:10px;
    -webkit-box-shadow: 0px 2px 3px 1px #C6C6C6;
    -moz-box-shadow: 0px 2px 3px 1px #C6C6C6;
    box-shadow: 0px 2px 3px 1px #C6C6C6;
    z-index:9999999;
    display: none;

    .messageFlash {
      margin-top:20px;
    }

    .galleryImageHolder, #galleryImageHolder {
      width:100%;
      margin:0;
      padding:0;

      li {
        display:block;
        text-align: center;
        min-height:150px;
        .image-holder.selected {
          padding:5px;
          border:2px solid $btn-success-bg;
          -webkit-box-shadow: 0px 0px 3px $btn-success-bg;
          -moz-box-shadow:0px 0px 3px $btn-success-bg;
          box-shadow: 0px 0px 3px $btn-success-bg;
        }

        .image-holder {
          cursor: pointer;
          cursor: hand;
          position: relative;
          height:150px;
          text-align: center;
          margin:20px auto;

          .options {
            position: absolute;
            bottom: 10px;
            left:0;
            right:0;
            margin:auto;
            display: none;
          }

          img {
            height:100%;
            width: auto;
          }



          &:hover {
            .overlay {
              width:100%;
              height:100%;
              position:absolute;
              background-color:#000;
              opacity:0.5;
            }

            .options {
              display: block;
            }
          }




        }


      }
    }

    #imageloader {
      width:100%;
      margin:0;
      padding:0;

      li {
        display:block;
      }

    }
  .sidebar {
    height: 600px;

    .dropzone-wrapper {
      height:100%;
      form {
        height: 100%;
      }

      .dz-preview {
        display: none !important;
      }

      .dz-default.dz-message {
        display: block !important;
      }
    }
  }

  }
}

.galleryVideoImageSelect, .gallery-tabs, ul.gallery_cat_holder {
  width:auto;
  margin:0;
  padding:0;

  li {
    display:block;
    float:left;
    padding:5px 10px;
    cursor: pointer;
    cursor: hand;
    margin: 0px 2.5px 10px 2.5px;
    border-bottom:4px solid transparent;

    &:hover {
      border-bottom:4px solid #2e6da4;
    }
  }

  li.active {
    font-weight:bold;
    border-bottom:4px solid #2e6da4;
  }

  li.upload-trigger {
    &:hover {
      border-bottom:4px solid transparent!important;
    }
  }
  li.upload-trigger.active {
    border-bottom:4px solid transparent!important;
  }
}
.popupContainmentArea .parentEditable {
  position: relative;
  z-index:2;
}
.homepage-hero-module {
  border-right: none;
  border-left: none;
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  z-index:0;

}
.no-video .video-container video,
.touch .video-container video {
  display: none;
}
.no-video .video-container .poster,
.touch .video-container .poster {
  display: block !important;
}
.video-container {
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  //background: #000;
  z-index:1;
}
.video-container .poster img {
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index:0;
}
.video-container .filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
}
.video-container video {
  position: absolute;
  z-index: 0;
  bottom: 0;
  top:0;
  left:0;
  right:0;
  margin:auto;
  width:100%;
  height:100%;

}
.video-container video.fillWidth {
  width: auto!important;
  height:auto;
  min-height:100%;
  min-width:100%;
}

.galleryVideos {
  height:75vh;
  overflow: auto;
  display:none;
  li {
    display:block;
    margin-bottom:20px;

    .insertVideo {
      position: absolute;
      bottom:10px;
      left:15px;
      right:15px;
      top:0;
      bottom:0;
      margin:auto;


      a {
        text-align: right;
        color:white;
        font-size:20px;
        text-decoration: none;
        display: block;
        position: absolute;
        bottom:0;
        right:0;
        width:100%;
        margin:auto;
        padding:40% 15px 0 0;
        height:100%;
      }


      &:hover {
        background: #000;
        opacity: 0.8;
        text-align: center;

        > .previewVideo {
          display:block;
        }
         a {

         }

      }
    }

  }

}


.admin-pagecreator-edit, .admin-pagecreator-editpublished, .admin-variations-edit, .admin-pagecreator-full,
.page-api-permissions-show, .shopify-leadslidesdk-pages-edit, .wordpress-sdk-pages-edit {

  .linking-options {
    .popup-list-select {
      width:100%;
      margin:0;
      padding:0;

      li {
        display:block;
        cursor: pointer;
        cursor: hand;
        .popup-image {
          text-align:center;
          float:left;
          width:50px;
          margin-right:5px;
          line-height:50px;
          font-size:22px;
        }
        .popup-name {
          float:left;
          font-size:18px;
          color:white;
          line-height:50px;
        }

        .popups {
          width:100%;
          margin:0;
          padding:0;
          li {
            display:block;
            clear:both;

          }
        }
      }
    }
  }
  .fixed.nav-wrapper {
    position: fixed;
    width:100%;
    z-index:1300;
    top:0;
  }

  .edit-block.has_guides {
    outline: 1px dashed $btn-info-border;
  }

  .column-block {
    position:relative;
    z-index:400;

    .edit-block {
      //z-index:400;
    }

    &:hover {
      &:before {
        z-index:0;
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:0px 15px 0px 15px;
        outline: 1px solid $dark-grey-seven;
      }
    }
  }

  #pagePreviewC {
    .column-block {
      position:relative;
      z-index:400;

      .edit-block {
        //z-index:400;
      }

      &:hover {
        &:before {
          z-index:0;
          content:'';
          position:relative;
          top:0;
          bottom:0;
          left:0;
          right:0;
          margin:0px;
          outline: none;
        }
      }
    }
  }

  .remove-column {
    position: absolute;
    z-index:1500;
    right:0px;
    content:'';
    top:-10px;
    width:30px;
    height:30px;
    margin:auto;
    display:none;

    a {
      line-height: 30px;
      text-align:center;
      font-size:16px;
      width:30px;
      height:30px;
      display:block;
      border-radius: 100%;
      padding:0;
      margin:0;
      border:none;
      background:$dark-grey-seven;
      color:white;
    }
  }
  .insert-new-block {
    position: absolute;
    left:0;
    right:0;
    bottom:-20px;
    margin:auto;
    width:40px;
    height:40px;
    z-index:1900;
    display:none;

    a {
      line-height: 40px;
      text-align:center;
      font-size:28px;
      width:40px;
      height:40px;
      display:block;
      border-radius: 100%;
      padding:0;
      margin:0;
      color:white;
      background:$dark-grey-seven;
    }
  }
  .insert-new-inner-block {
    position: absolute;
    //bottom:-20px;
    left:0;
    right:0;
    margin:auto;
    width:30px;
    height:30px;
    z-index:1900;
    display:none;

    a {
      line-height: 30px;
      text-align:center;
      font-size:24px;
      width:30px;
      height:30px;
      display:block;
      border-radius: 100%;
      padding:0;
      margin:0;
      //border:1px solid $alert-success-border;
      border:none;
      color:white;
      opacity: 0.8;
      background: $dark-grey-seven ;
    }
  }

  .col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    .insert-new-inner-block {
      left:0;
      //top:0;
      bottom:-15px;
      right:0;
    }

    &:hover {
      > .insert-new-inner-block {
        display: block;
      }

      > .insert-new-block {
        display: block;
      }
      > .remove-column {
        display: block;
      }
    }
  }
  .navigation-block.standard-block {
    li {
      display:block;
      margin:10px 0;
    }
  }
  #individualOpts {
    input[type='text'], input {
      color:black !important;
    }
  }
  .nedit-holder {
    margin:0;
    padding:0;
    input {
      max-width:100%;
      color:black;
    }

  }
  .slider-holder {
    margin:0;
    padding:0;
  }

  .modal-content {
    .modal-header,.modal-footer {
      border:none!important;
    }
  }

.url_link_select_list, .icon_select_list, .block_select_list {
  margin:0px;
  margin:0px;
  li {
    display:block;

    .options {
      display: none;
      width:100%;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      text-align:center;
      background:rgba(0,0,0,0.7);

      div {
        display: table-cell;
        width:100%;
        height:150px;
        vertical-align: middle;
        a {
          color:white;
          font-size:18px;

          &:hover {
            text-decoration: none;
            opacity:0.9;
          }
        }
      }

    }

    &:hover {
      .options {
        display: table;
        width:100%;
        height:150px;
      }
    }

  }
}


  .pagecreator-nav {
    padding:0;
    margin:0;
    //position: fixed;
    //display:none;fw4484

    .current-background-image, .current-active-image {
      width:150px;
      height:150px;
      border: 1px solid white;
      padding:5px;
      text-align:center;
      position:relative;
      background-size: cover;
      background-position: 50% 50%;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;

      .addImage {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        height:38px;
        color:white;

      }
      img {
        max-width:100%;
        height:auto;
      }
    }

    .remove-image {
      width:150px;
      text-align:center;

      a {
        color:white;
      }
    }

    .template-options {
      width:100%;
      padding:0;
      margin-right:0;
      background: $dark-grey-eight;

      div.option {
        float:left;
        width:100px;
        height:100px;
        text-align: center;
        a {
          font-size:48px;
          color: $dark-grey-seven;
          display:block;
          line-height: 100px;
          &:hover {
            background: $dark-grey-seven;
            color:white;
          }
        }
      }
    }

    .single-template-options {

      .option {
        display: block;
        float: left;
        padding: 15px;
        color: #62727f;
        font-size: 34px;

        a {
          color:$dark-grey-six;

          &:hover {
            color:$dark-grey-five;
          }
        }
      }
    }

    .individual-options {
      padding:20px 0;
      display:none;
      background: $dark-grey-eight;
      color:white;

      .outer-blocks,.internal-blocks {
        display: none;
      }

      .dropzone-wrapper {
        width:100%;
        height:150px;
        overflow-x:hidden;
        overflow-y: scroll;

        i {
          font-size:3.2vw;
        }
        .dz-success {
          display:none;
        }
        .dropzone.dz-started .dz-message {
          display:block;
        }


      }
      #imageScroller, #imageScrollerImage {
        width:100%;
        height:127px;
        padding:0px;
        overflow-x:scroll;
        overflow-y: hidden;

        .image-holder {
          .image-item-none {
            i {
              font-size:50px;
            }
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 115px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              i {
                color:$light-grey-one;
              }
            }
          }
          .image-item {
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 90px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              img {
                opacity: 0.5;
              }
            }
            img {
              max-height: 90px;
              width:auto;
            }
          }
        }
      }

      .col-md-1 {
        padding:0;
        text-align: center;
        .backNav {
          color: $dark-grey-nine;
          font-size:4.2vw;
          display:block;
          &:hover {
            color:white;
          }
        }
      }

      .colourpicker {
        min-height: 26px;
        line-height: 26px;
      }

      .admin-checklist {
        width:100%;
        margin:0;
        padding:10px 0 0 0;
        li {
          .title {
            h3 {
              margin:0 0 10px 0;
            }
          }
          display:block;
          ul {
            padding-left:20px;
          }
          a {
            color:white;
          }
        }
        li.checked {
          a {
            text-decoration: line-through;
          }
          i {
            color:$valid-color;
          }
        }
        li.unchecked {
          i {
            color:$invalid-color;
          }
        }
      }
      .options {
        display: none;
      }
    }
    .individual-options {
      position:absolute;
      width:100%;
      top:0;
      left:0;
      right:0;
      z-index:800;
      ul {
        margin:0;
        padding:0;
        li {
          display:block;
          .slider-holder {
            margin:12px 10px 0 0;
          }

        }
      }
    }
    .individual-options.fixed {
      position: fixed;
      z-index:1000;
      width:100%;

      //margin-top:-6%;

    }

  }
}

.individual-options.fixed {
  top:80px!important;
}

@media only screen and (min-width: 1090px) and (max-width: 9000px) {
  .individual-options.fixed {
    top:80px!important;
  }
}

@media only screen and (max-width: 1089px) and (min-width: 100px) {
  .individual-options.fixed {
    //top:160px!important;
    top:80px!important;
  }
}


//block styles
.outer-block.list-image-block
{
  .container {
    min-height:380px;
  }
}

.imageCategoryFilter {
  li {
    display: block;
    float: left;
    padding: 5px 10px;
    cursor: pointer;
    cursor: hand;
    margin-bottom: 10px;

    &:hover {
      border-bottom: 4px solid #2e6da4;
    }
  }

  li.active {
    font-weight: bold;
    border-bottom: 4px solid #2e6da4;
  }
}

.holderAllImages {
  display:none;
}

.holderAllImages.active {
  display:block;
}
.outer-block {
  background-size:100%;
  .portfolio-block {
    padding:0;

    img {
      width:100%;
      height:auto;
    }
  }

  .inlineList-block {
    ul {
      width:100%;
      margin:0;
      padding:0!important;

      li {
        display:inline;
        margin:2px;
        padding:3px;
      }
    }
  }

  .testimonial-icon {
    font-size:34px;
    color:$dark-grey-seven;

    .name-holder {
      font-size:13px;
    }
  }

  .testimony-block {
    position:relative;
    background: #f6f3f3;
    font-size:12px;
    padding:20px;
    color:#747474;
    font-style: italic;

    &:after {
      border-left: solid transparent 0px;
      border-right: solid transparent 40px;
      border-top: solid #f6f3f3 16px;
      bottom: -16px;
      content: "";
      height: 0;
      left: 5%;
      position: absolute;
      width: 0;
    }
  }
  .navbar-leadslide {
    background:none;

    .navbar-toggle {
      background: $dark-grey-seven;
    }
    .icon-bar {
      background:white;
    }
    .navbar-brand {
      color:$dark-grey-seven;
    }
    ul.nav {
      li {
        a {
          color:$dark-grey-seven;

          &:hover {
            background:none;
            text-decoration: none;
          }
        }

        &:hover {
          background:none;
          text-decoration: none;
        }
      }
    }
  }
}


#pageSettingsSwitch {
  position: fixed;
  right:0;
  top:15%;
  z-index:400;
  text-align:center;
  overflow: hidden;
  width:auto;
  //height:55px;

  a {
    color:$dark-grey-seven;
    i {
      font-size:60px;
    }
  }

  #pageSettings {
    background:white;
    position: relative;
    z-index:99999999;
    right:-200px;
    width:0px;
    overflow: hidden;
    height:50px;

    #pageOptions {
      padding:0;
      margin:10px 0;

      li {
        padding:5px 10px;
        cursor: pointer;
        cursor: hand;
      }
    }
  }
}

.show-guides {
  z-index:200;
  content:'';
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  outline: 1px dashed $btn-info-border;
}
.show-guides.column-block {
  &:before {
    z-index:200;
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:0px 15px 0px 15px;
    outline: 1px dashed $btn-info-border;
  }
}

.leadslide-highlight {
  background: $btn-info-border!important;

}

.leadslide-placeholder-text {
  font-size:60px;
  color:white;
}

.nav-block {
  min-height:20px;
}
#navBlockItems {
  margin:0;
  li {
    display:inline;
    padding:5px;
    margin:0 2px;
    position: relative;

    a {
      font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
      color:white;
    }
    .remove-list-item {
      color:white!important;
      font-size:12px;
      cursor:hand;
      cursor:pointer;
      left:-10px;
      top:-0px;
      width:15px;
      height:15px;
      line-height: 15px;
      text-align: center;
      font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
      position: absolute;

    }
    font-family: "Georgia", Times, serif;
  }
}

.company-name-link-block {
  display:none;

}
.ls_outer_placeholder {
  background:$btn-info-border;
  color:white;
  font-size:40px;
  font-weight:bold;
  padding:5%;
  text-align: center;
}

.ls_highlight {

  &:after {
    position: absolute;
    opacity: 0.9;
    z-index:1400;
    background: $btn-info-border;
    color:white;
    content:'Block Will Be Inserted Here';
    top:0;
    bottom:0;
    padding:20%;
    text-align: center;
    font-size:2.4vw;
    font-size:28px;
    width:100%;
    height:100%;
    margin: auto;
    left:0;
    right:0;

  }
}

.ls_highlight.danger {
  &:after {
    background:$btn-danger-border;
    content:'This block and all internal blocks will be removed';
  }
}

.padding-options {
  ul {
  }
}

#backShadow {
  background:black;
  opacity: 0.8;
  position: fixed;
  z-index:2300;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  content: '';
  width:100%;
  height:100%;
  display:none;
}

#publishPagesForm {
  display:none;
}

.flash-holder {
  clear:both;
  position: relative;
  margin:10px auto;
  padding:10px;
  width:100%;

  .flash {
    p {
      color:$dark-grey-seven;
    }
  }
}

#publishPopUp {
  position: fixed;
  width:75%;
  height:auto;
  background:white;
  top:15%;
  left:0;
  right:0;
  z-index:2400;
  margin-left:auto;
  margin-right:auto;
  border-radius:20px;
  padding:20px;
  box-shadow:0px 0px 3px 1px #C6C6C6;
  display:none;

  .close-button {
    position: absolute;
    top:20px;
    right:20px;
    z-index:1300;
  }

  .publish-title {
    h2 {
      text-transform: uppercase;
    }
    margin-bottom:50px;
  }

  .publishList {
    padding:0;
    margin:0;

    li {
      display:block;

      .image-holder {
        position: relative;
        width:70%;
        //min-height:150px;
        margin:0 auto 20px auto;
        background:$dark-grey-four;
        overflow: hidden;

        img {
          max-height: 100%;
          max-width: 100%;

          width:auto;
        }
      }

      .pub-info {
        a {
          color:$dark-grey-eight;

          &:hover {
            color:$dark-grey-seven;
            text-decoration: none;
          }
        }
      }
    }
  }

  .publish-footer {
    padding:20px;
    margin-top:20px;
  }
}

#blockController {
  position: fixed;
  width:95%;
  height:95%;
  background:white;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:2400;
  margin:auto;
  border-radius:20px;
  box-shadow:0px 0px 3px 1px #C6C6C6;
  display:none;
  .close-button {
    position: absolute;
    top:20px;
    right:20px;
    z-index:1300;
  }

  .pageBlockTabs {
    width:100%;
    padding:20px 0;
    margin:0;

    li {
      display:inline;
      padding:10px 20px;
      margin:0 5px;

      a {
        color:$dark-grey-seven;
        font-size:1.6em;
        &:hover {
          color:$btn-info-border;
          text-decoration: none;
        }

      }
    }

    li.active {
      a {
        color:$btn-info-border;
        text-decoration: none;
      }
    }
  }

  .blockItems {
    display:none;

    .blockHolder {
      margin:0;
      padding:0;
      width:100%;
      clear:both;


      li {
        display:block;
        padding:3px;
        border:2px solid $dark-grey-seven;
        border-radius:5px;
        margin:5px;
        text-align:center;
        width:32%;
      }

      li.active {
        border:2px solid $btn-info-border;
      }
    }
  }

  .blockPreviews {
    .preview-box {
      width:60%;
      margin:10px auto;
      min-height:100px;
      border:1px dashed $dark-grey-seven;
      border-radius:5px;
      text-align:center;

      img {
        height:auto;
        width:100%;
      }
    }

    .button-holder {
      text-align:center;
      a {
        font-size:18px;
      }
    }


  }




}


//block types
.pagecreator-container {
  background: white;//default
  .outer-options {
    width:210px;
    position: absolute;
    top:0px;
    left:0px;
    display:block;
    z-index:1100;
  }
  .outer-block {
    background-color: white;//default
  }

  .insInnerBlock {
    position: absolute;
    width:30px;
    height:30px;

    text-align:center;
    bottom:30px;
    margin:auto;
    left:0;
    right:0;
    z-index:600;
    background:$dark-grey-eight;
    border-radius:100%;

    i {
      color:white;
      line-height:30px;
      width:30px;
      height:30px;
      
      &:hover {
        cursor: pointer;
        cursor: hand;
      }
    }
  }


  .full-container {
    position: relative;
    width:100%;
    margin:auto;
    padding:0 !important;

    &:after {
      content: " ";
      display: table;
      clear:both;
    }

    &:before {
      content: " ";
      display: table;
    }

    img {
      width:100%;
      max-width:100%;
      height:auto;
    }
  }


  .outer-block:first-child {
    //border-top: 1px dashed $btn-info-border;
  }

  .outer-block {
    display:block;
    position: relative;
    clear:both;
    //border-bottom: 1px dashed $btn-info-border;



    .container, .full-container {
      //padding:60px 0;
      position: relative;
      //border-left: 1px dashed $btn-info-border;
      //border-right: 1px dashed $btn-info-border;
      //min-height:120px; //default height


      .removepadding {
        padding:0px;
      }

      &:hover {
        > .insert-new-block {
          display: block;
        }
      }

      .edit-block {
        width:auto;
        max-width:100%;

        clear:both;
        position: relative;

        ul {
          padding:0;
          margin:0;

        }
        &:hover {

        }

        .edit-block-options {
          position: absolute;
          top:-35px;
          left:0;
          display: none;
          z-index:401;
          min-width:160px !important;
        }
      }
    }
  }
}

#sitemap {
  margin-top:20px;
  .page {
    text-align: center;
  }
 .root-page {
   border-bottom: 2px solid $dark-grey-seven;
   //height:80px;
   .page {
     padding:20px;
     border: 2px solid $dark-grey-seven;
     position: relative;
     width:200px;
     left:0;
     right:0;
     margin:auto;
     margin-bottom:20px;

     &:after {
       position: absolute;
       content:'';
       margin:auto;
       left:0;
       right:0;
       bottom:-22px;
       width:2px;
       height:20px;
       background: $dark-grey-seven;

     }
   }
   .no-page {
     border: 2px dashed $dark-grey-seven;
     cursor: pointer;
     cursor: hand;
   }

 }
  .alt-pages {
    .center-wrapper {
      margin:auto;
      width:auto;
      display:table;

    }

    .page-ul {
      display: table;
      width:100%;
      li {
        display:table-cell;
        margin:auto;
        padding:20px;
        width:200px;
        position: relative;

        &:before {
          position: absolute;
          margin: auto;
          content: '';
          left:0;
          right:0;
          top:0px;
          width:2px;
          background: $dark-grey-seven;
          height: 20px;
        }

        .page-inner {
          border: 2px solid $dark-grey-seven;
          padding:20px;
          width:200px;
          margin:auto;
        }

        .no-page {
          cursor: pointer;
          cursor: hand;
          border: 2px dashed $dark-grey-seven;
        }

      }
    }
  }
}

#pageSelector {
  position: fixed;
  width:95%;
  height:95%;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  background: white;
  border-radius:20px;
  z-index: 2400;
  padding:20px;

  .pageList {
    margin:0;
    padding:0;
    width:100%;

    li {
      display:block;
      img {
        width:100%;
        height:auto;
      }
      padding:2px;
    }
    li.active {
      border:1px solid $btn-success-border;
    }
  }

  .selected {
    text-align: center;
    width:60%;
    margin:auto;
    min-height:150px;
    border: 1px dashed $dark-grey-seven;
  }

  .btn-holder {
    text-align: center;
    margin-top:20px;
  }
}

.navbar-brand.img-block {
  width:100%;
}
.navbar-header {
  min-width:25%;
}

.admin-pagecreator-block, .admin-blocks-edit, .leadslidesdk-pages-edit, .leadslidesdk-pagecreator-block {
  background: $dark-grey-five;
  position: relative;
  z-index:2;
  //min-width: 1300px !important;
  min-width: 800px !important;



  #fullPageLoader {
    display:none;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height:100%;
    width:100%;
    margin:auto;
    z-index:900;
    background: black;
    color:white;
    font-size:5vw;
    text-align:center;
    opacity:0.9;

    .spinner-block {
      width:100%;
      height:60px;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      position:absolute;

    }
  }

  #individualOpts {
    position: relative!important;
  }

  .outer-block.custom-block {
    margin-bottom:0px!important;

  }

  .activeBlock, .active-button, .active-image, .active-nav {
    outline:1px dashed $btn-info-bg;
  }



  .moveItem {
    position: absolute;
    color:$btn-info-border;
    height:50%;
    right:5px;
    font-size:18px;
    padding:1px 10px;
  }

  .column-block.col-md-12 {
    .edit-block {
      .moveItem {
        right:30px;
      }
    }
  }

  .moveItem.up {
    top:0;

    i {
      position: absolute;
      top:0;
    }

  }

  .moveItem.down {
    bottom:0;

    i {
      position: absolute;
      bottom:0;
      width:10px;
      height:10px;
    }
  }





  #block-builder-wrapper {
    position: relative;
    z-index:2
  }

  .custom-block {
    //margin-bottom:10px;
    left:auto !important;
  }

  #hiddenGrid {
    background: url('../images/blocks/base_grid.png') repeat-y;
    background-size:1300px; width:1300px; opacity:0.7;
    position: absolute;
    height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    z-index:3;
  }
  .edit-outer-options {
    z-index:4;
    .btn-default {
      background: $dark-grey-eight;
      font-size:24px;
      i, span {
        color:$dark-grey-six;
      }

      &:hover {
        i, span {
          color:white;
        }
      }
    }
  }
  #outerHolderBlock {
    position: relative;
    z-index:4;
  }
  h1,h2,h3,h4,h5,h6,p {
    //margin:0;
    //padding:0;
  }

  .show-guides {
    position: relative;
    z-index:1;
    .column-block {
      &:before {
        z-index:0 !important;
      }
      &:after {
        z-index:0 !important;
      }
    }
  }
  .hide-opacity {
    .inner {
      opacity: 1 !important;
    }
    .backcover {
      .inner {
        opacity: 1 !important;
      }
    }
  }

  .hide-grid {
    .inner:first-child {
      background: none!important;
    }

    .removeBc.backcover {
      display:none;
    }
  }

  input[type="number"]
  {
    color:black;
  }
  .moveEditBlock {
    //display:none!important;
  }
  .no-padding {
    padding:0;
  }
  .edit-outer-options {
    position: absolute;
    top:0;
    left:0;
    width:60px;
  }

  .editBlock {
    position:absolute;
    top:-30px;
    left:15px;
    width:160px;
  }

  .edit-block-options
  {
      position:absolute;
      top:-25px;
      left:0px;
      right:0;
      margin:auto;
      //width:118px;
      width:168px;
      display:none;
  }
  .img-block {
    .edit-block-options {
      top:-10px;
    }
  }

  .insert-new-inner-block {
    position: absolute;
    //bottom:-20px;
    left:0;
    right:0;
    margin:auto;
    width:30px;
    height:30px;
    z-index:1900;
    display:none;

    a {
      line-height: 30px;
      text-align:center;
      font-size:24px;
      width:30px;
      height:30px;
      display:block;
      border-radius: 100%;
      padding:0;
      margin:0;
      //border:1px solid $alert-success-border;
      border:none;
      color:white;
      opacity: 0.8;
      background: $dark-grey-seven ;
    }
  }

  .ls-template-nav {
    background:white;
  }

  .ls-logo {
    background:black;
    h1 {
      margin:21px auto;
    }
  }
  .fixed.nav-wrapper {
    position: fixed;
    width:100%;
    z-index:1300;
  }

  .single-template-options {

    .option {
      display: block;
      float: left;
      padding: 15px;
      color: #62727f;
      font-size: 34px;

      a {
        color:$dark-grey-six;

        &:hover {
          color:$dark-grey-five;
        }
      }
    }
  }

  .individual-options {
    padding:20px 0;
    display:none;
    background: $dark-grey-eight;
    color:white;

    .dropzone-wrapper {
      width:100%;
      height:150px;
      overflow-x:hidden;
      overflow-y: scroll;

      i {
        font-size:3.2vw;
      }
      .dz-success {
        display:none;
      }
      .dropzone.dz-started .dz-message {
        display:block;
      }
    }
    #imageScroller, #imageScrollerImage {
      width:100%;
      height:127px;
      padding:0px;
      overflow-x:scroll;
      overflow-y: hidden;

      .image-holder {
        .image-item-none {
          i {
            font-size:50px;
          }
          border:1px dashed $light-grey-two;
          text-align: center;
          width:100px;
          height:100px;
          padding:5px;
          margin:5px;
          line-height: 115px;
          cursor: hand;
          cursor: pointer;
          float:left;
          &:hover {
            i {
              color:$light-grey-one;
            }
          }
        }
        .image-item {
          border:1px dashed $light-grey-two;
          text-align: center;
          width:100px;
          height:100px;
          padding:5px;
          margin:5px;
          line-height: 90px;
          cursor: hand;
          cursor: pointer;
          float:left;
          &:hover {
            img {
              opacity: 0.5;
            }
          }
          img {
            max-height: 90px;
            width:auto;
          }
        }
      }
    }

    .col-md-1 {
      padding:0;
      text-align: center;
      .backNav {
        color: $dark-grey-nine;
        font-size:4.2vw;
        display:block;
        &:hover {
          color:white;
        }
      }
    }

    .colourpicker {
      min-height: 26px;
      line-height: 26px;
    }

    .admin-checklist {
      width:100%;
      margin:0;
      padding:10px 0 0 0;
      li {
        .title {
          h3 {
            margin:0 0 10px 0;
          }
        }
        display:block;
        ul {
          padding-left:20px;
        }
        a {
          color:white;
        }
      }
      li.checked {
        a {
          text-decoration: line-through;
        }
        i {
          color:$valid-color;
        }
      }
      li.unchecked {
        i {
          color:$invalid-color;
        }
      }
    }
    .options {
      display: none;
    }
  }

  .insBlock {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    margin:auto;
    text-align: center;
    cursor: pointer;
    cursor: mouse;

    i {
      width:50px;
      height:50px;
      position: absolute;
      top:0;
      bottom: 0;
      left:0;
      right:0;
      margin:auto;
      font-size:50px;
      line-height: 50px;
      color:$btn-info-border;
    }

    &:hover > i {
      color:$dark-grey-six;
    }

  }

  #background-image {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index:0;
    margin:auto;
  }

  .insert-new-inner-block {
    position: absolute;
    //bottom:-20px;
    left:0;
    right:0;
    bottom:-15px;
    margin:auto;
    width:30px;
    height:30px;
    z-index:1900;
    display:none;

    a {
      line-height: 30px;
      text-align:center;
      font-size:24px;
      width:30px;
      height:30px;
      display:block;
      border-radius: 100%;
      padding:0;
      margin:0;
      //border:1px solid $alert-success-border;
      border:none;
      color:white;
      opacity: 0.8;
      background: $dark-grey-seven ;
    }
  }

  .editBl {
    display:none;
    position: absolute;
    width:160px;
    top:-29px;
    //top:0px;
    left:15px;
    z-index:600;
  }

  .backcover {
    padding:0px;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    z-index:0;

    .inner {
      background:white;
      opacity:0.5;
      width:100%;
      height:100%;
      z-index:0;
    }
  }

  .resizable {
    //overflow: hidden;
  }
  .edit-block {
    position: relative;
    z-index:2;
    //min-height:140px;

  }

  .pagecreator-nav {
    padding:0;
    margin:0;
    position: relative;
    z-index:300;

    .current-background-image, .current-active-image, .current-active-fixed-image {
      width:150px;
      height:150px;
      border: 1px solid white;
      padding:5px;
      text-align:center;
      position:relative;
      background-size: cover;
      background-position: 50% 50%;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;

      .inner-hover {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.7);
        display: none;
        width:100%;

        .inner {
          height:150px;
          display: table-cell;
          vertical-align: middle;
        }

      }

      &:hover {
        .inner-hover {
          display: table;
        }
      }


      .addImage {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        height:38px;
        color:white;

      }
      img {
        max-width:100%;
        height:auto;
      }
    }

    .remove-image {
      width:150px;
      text-align:center;

      a {
        color:white;
      }
    }

    .template-options {
      width:100%;
      padding:0;
      margin-right:0;
      background: $dark-grey-eight;

      div.option {
        float:left;
        width:100px;
        height:100px;
        text-align: center;
        a {
          font-size:48px;
          color: $dark-grey-seven;
          display:block;
          line-height: 100px;
          &:hover {
            background: $dark-grey-seven;
            color:white;
          }
        }
      }
    }

    .single-template-options {

      .option {
        display: block;
        float: left;
        padding: 15px;
        color: #62727f;
        font-size: 34px;

        a {
          color:$dark-grey-six;

          &:hover {
            color:$dark-grey-five;
          }
        }
      }
    }

    .individual-options {
      padding:20px 0;
      display:none;
      background: $dark-grey-eight;
      color:white;

      .outer-blocks,.internal-blocks {
        display: none;
      }

      .dropzone-wrapper {
        width:100%;
        height:150px;
        overflow-x:hidden;
        overflow-y: scroll;

        i {
          font-size:3.2vw;
        }
        .dz-success {
          display:none;
        }
        .dropzone.dz-started .dz-message {
          display:block;
        }
      }
      #imageScroller, #imageScrollerImage {
        width:100%;
        height:127px;
        padding:0px;
        overflow-x:scroll;
        overflow-y: hidden;

        .image-holder {
          .image-item-none {
            i {
              font-size:50px;
            }
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 115px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              i {
                color:$light-grey-one;
              }
            }
          }
          .image-item {
            border:1px dashed $light-grey-two;
            text-align: center;
            width:100px;
            height:100px;
            padding:5px;
            margin:5px;
            line-height: 90px;
            cursor: hand;
            cursor: pointer;
            float:left;
            &:hover {
              img {
                opacity: 0.5;
              }
            }
            img {
              max-height: 90px;
              width:auto;
            }
          }
        }
      }

      .col-md-1 {
        padding:0;
        text-align: center;
        .backNav {
          color: $dark-grey-nine;
          font-size:4.2vw;
          display:block;
          &:hover {
            color:white;
          }
        }
      }

      .colourpicker {
        min-height: 26px;
        line-height: 26px;
      }

      .admin-checklist {
        width:100%;
        margin:0;
        padding:10px 0 0 0;
        li {
          .title {
            h3 {
              margin:0 0 10px 0;
            }
          }
          display:block;
          ul {
            padding-left:20px;
          }
          a {
            color:white;
          }
        }
        li.checked {
          a {
            text-decoration: line-through;
          }
          i {
            color:$valid-color;
          }
        }
        li.unchecked {
          i {
            color:$invalid-color;
          }
        }
      }
      .options {
        display: none;
      }
    }
    .show-guides
    {
      z-index:1 !important;
      .column-block {
        &:before {
          z-index:1!important;
        }
        &:after {
          z-index: 1!important;
        }
      }
    }
    .individual-options {
      //position:absolute;
      width:100%;
      top:0;
      left:0;
      right:0;
      z-index:1200;
      ul {
        margin:0;
        padding:0;
        li {
          display:block;
          .slider-holder {
            margin:12px 10px 0 0;
          }

        }
      }
    }
    .individual-options.fixed {
      position: fixed;
      z-index:1300000;
      width:100%;

      //margin-top:-6%;
    }
  }
  .popUpContainment {
    top:0!important;
  }
  .outer-block.custom-block {
    position: relative;
    min-height:350px;



    .ins-bc-block {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      margin: auto;
      width: 80px;
      height: 40px;
      z-index: 1900;

      a {
        line-height: 40px;
        text-align: center;
        font-size: 28px;
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 100%;
        padding: 0;
        margin: 0;
        color: white;
        background: #62727f;
      }
    }
  }
}

.admin-blocks-screenshot {
  .remove {
    display:none;
  }

  #fullPageLoader {
    display:block;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height:100%;
    width:100%;
    margin:auto;
    z-index:900;
    background: black;
    color:white;
    font-size:5vw;
    text-align:center;
    opacity:0.9;

    .spinner-block {
      width:100%;
      height:60px;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      position:absolute;

    }
  }

}

.icon-nav-wrapper {
  width:150px;
  height:150px;
  text-align:center;
  border:1px dashed $dark-grey-seven;
  margin-bottom:10px;
  line-height:150px;

  i {
    font-size:90px;
    line-height:150px;
  }
}

.iconToInsertList {
  li {
    display:block;
    text-align:left;
    margin-left:10px;
    margin-bottom:10px;
    
    &:hover {
      cursor: pointer;
      cursor: hand;
    }
    i {
      color:white!important;
      font-size:30px !important;
    }
  }
}

.InsertNewSocialIcon {
  display:none;
}

.pages-faqs-index {
  .bs-example-tabs {

    .tab-pane {
      padding-top:20px;
    }
    h4 {
      margin-top:20px;
      font-size:20px;
    }

    h3 {
      margin-top:20px;
      font-szie:18px;
    }
    p {
      margin-bottom:1.3em;
      font-size:17px;

    }
  }
}
.shopify-apps {


  .leadslideOptions {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    background:rgba(0,0,0,0.8);
    width:100%;
    height:100%;
    display: none;
    z-index:3;

    .option {
      position: absolute;
      top:10%;
      width:80%;
      max-width:600px;
      background:white;
      border-radius:10px;
      margin:auto;
      padding:20px;
      left:0;
      right:0;
      display: none;

      .close-option.topClose
      {
        z-index:4;
        position: absolute;
        width:20px;
        height:20px;
        text-align: center;
        right:5px;
        top:5px;

        &:hover {
          cursor: hand;
          cursor: pointer;
          opacity: 0.8;
        }
      }

      .close-option.bottomClose {
        margin-top:20px;
        width:100%;
      }
    }

    .option.active {
      display: block;
    }
  }

  .notification-bar {
    #fontList {
      display: block;
      margin:0;
      padding:0;

      li {
        display:block;
      }
    }

    .popOpts {

    }
    .promoBarPreview {
      position: relative;
      margin-top:40px;

      .example-site-img {
        width:100%;
        height:auto;
      }
      .leadSlideNotificationBar {
        display:none;

        .social-button {
          margin-right:5px;
        }


        p {
          margin-bottom:0px;
        }

        .lsContent {
          width:100%;
          padding:0px 15px;
          height:40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .leadSlideNotificationBar.active {
        display: block;
        position: absolute;
        width:100%;
        height:40px;
        padding:0;
        margin:auto;
        left:0;
        right:0;

      }
    }
  }

  .upcross-marketing-page {

    .editUpsellP {
      width:50px;
      height:50px;
      position: absolute;
      top:0;
      left:0;
    }

    td {
      vertical-align: middle !important;
    }
    .show_sp_products_fr_sel {


      .ss_sp_fr_button {
        height:75px;
      }
      .ss_sp_fr_title {
        height:75px;
      }

      .ss_sp_fr_image {
        text-align:center;
        img {
          height:75px;
          width:auto;
        }
      }
    }

    .sidebarBackButton {
      font-size:20px;
      color:white;
      border:1px solid white;
      margin:10px 0;
      padding:10px 20px;
      display:block;
    }
    .main-edit-options {
      padding:20px 0;
      label {
        color:white;
      }
    }

    .sideOption {
      label {
        color:white;
      }
    }

    .block-list {
      margin:0;
      padding:0;
      width:100%;


      li {
        width:100%;
        display: block;

        a {
          display: block;
          padding:10px 20px;
          color:white;
          border-bottom:1px solid #444;
          font-size:20px;
          &:hover {
            text-decoration: none;
            opacity:0.9;
          }
        }
      }
    }
    .currentApWrapper {
      width:100%;
      padding:20px;
      background:#f8f8f8;
      margin-top:20px;
      position: relative;
      min-height:30px;


      .ifHasProducts {
        position:absolute;
        width:150px;
        height:38px;
        bottom:20px;
        right:0;
        z-index:2;

        a {
          display: block;
          line-height:30px;
          margin-top:0px!important;
        }
      }
    }

    .select_sp_pro_fg {
      margin-bottom:40px;
    }

    .popup_on_selected_only {
      padding:20px;
      background:#f8f8f8;
      .col-sm-3 {
        position:relative;
        margin-bottom:10px;
        min-height: 70px;

        .remove_selected_product_fr {
          width:20px;
          height:20px;
          line-height:20px;
          font-size:16px;
          color:black;
          position: absolute;
          top:-2px;
          right:-2px;

          &:hover {
            cursor: pointer;
            cursor: hand;
            opacity:0.9;
          }
        }
      }

      .pop_select_product {
        .inner {
          padding: 5px 10px;
          border: 1px dashed;
          position: relative;
          margin-right:20px;
        }

        .remove_pop_select_product {
          position: absolute;
          width:20px;
          height:20px;
          text-align: center;
          z-index:1;
          top:-5px;
          right:-5px;
          line-height:20px;
          font-size:16px;

          &:hover {
            cursor: pointer;
            cursor: hand;
          }
        }
      }
    }
    .currentProductsSelectedAR {



      .singleProduct {
        padding: 5px 10px;
        border: 1px dashed;
        position: relative;
        margin-right:20px;

        .alternateRemoveProduct {
          position: absolute;
          width:20px;
          height:20px;
          text-align: center;
          z-index:1;
          top:-5px;
          right:-5px;
          line-height:20px;
          font-size:16px;

          &:hover {
            cursor: pointer;
            cursor: hand;
          }
        }
      }
    }
    .product-results-container {
      position: relative;
      height: 150px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .current-step {
      width:281px;
      margin:auto;
      text-align:center;

      li {
        display: block;
        float: left;
        width: 50px;
        height: 50px;
        background: $dark-grey-twelve;
        border-radius: 100px;
        text-align: center;
        margin:5px;
        &:hover {
          opacity:0.8;
        }
        .step {
          float:left;
          color:white;
          display: block;
          width:100%;
          height:100%;
          line-height:50px;
          text-decoration: none;

        }

      }

      li.active {
        background: $btn-primary-three-border;
      }

    }

    .step-options {
      .stepopts {
        display:none;
      }
      .stepopts.active {
        display: block;
      }
    }

    .layout-type {
      li {
        display: block;
        min-height:150px;
        text-align:center;

        &:hover {
          cursor: hand;
          cursor: pointer;
        }
      }
      li.active {
        outline: 1px dotted $btn-success-border;
      }
    }

    #productsContainer {
      position: relative;
    }
    .ui-autocomplete {
      position: absolute;
      z-index:200;
      bottom:0;
    }

    .filter-results  {
      background: $dark-grey-five;
      min-height:200px;
      padding:20px;
      margin-bottom:20px;

      .product-item {
        width:100%;
        clear:both;
        margin-bottom:8px;
        min-height:85px;
      }

    }

    .selectHolder {
      line-height:40px;
    }

    .products-added {
      background: $dark-grey-five;
      border: 1px dashed $dark-grey-twelve;
      min-height:510px;
      padding:20px;
      margin-bottom:20px;

      .product-item {
        width:100%;
        clear:both;
        margin-bottom:8px;
        min-height:85px;
      }
    }

    .designArea {
      width:100%;
      min-height:750px;
      background:url('../images/shopify/apps/upsells/example-site.png');
      background-size:cover;
      position: relative;
      overflow-x: hidden;

      #toggleSettings {
        color:white;
        position: relative;
        z-index: 3;
        text-decoration: none;
        margin: 20px 10px;
        font-size:58px;

        &:hover {
          opacity:0.9;
        }
      }

      .side-settings-bar {
        width: 50%;
        left:-50%;
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        background: rgba(0,0,0,0.89);
        z-index: 3;

        .close-sidebar {
          width:30px;
          height:30px;
          position: absolute;
          top:0px;
          right:0px;
          line-height:30px;
          color:white;
          font-size:18px;
          text-align: center;
          margin:auto;
          z-index:2;

          &:hover {
            cursor: pointer;
            cursor: hand;
            opacity: 0.9;
          }
        }
        .sideOption {
          display: none;
        }
        .main-edit-options {
          .option {
            display:none;
          }
        }
      }

      .lsPopContent {
        .col-sm-1, .col-sm-2, .col-sm-3,
        .col-sm-4, .col-sm-5, .col-sm-6,
        .col-sm-7, .col-sm-8, .col-sm-9,
        .col-sm-10, .col-sm-11, .col-sm-12
        {
          margin-bottom: 15px;
        }
        h5 {
          margin-bottom:15px;
        }
        p {
          margin-bottom:25px;
        }


        img {
          max-height:140px;
          margin-top:15px;
        }
      }
      .layout {
        display:none;
      }


      .layout.active {
        display:block;
        position: absolute;
        z-index:2;
        top:0;
        bottom:0;
        margin:auto;
        left:0;
        right:0;
        width:100%;
        height:100%;
        background: rgba(0,0,0, .7);
        padding-bottom:80px;
        padding-top:80px;

        .leadslideUpsellPopup.med {
          width:60%;
        }

        .leadslideUpsellPopup.lsLarge {
          width:75%;
        }

        .freshStart {
          position: absolute;
          top:45px;
          padding:20px;
          left:0;
          right:0;
          margin:auto;
          height:auto;
          z-index:9000;
          overflow-y: auto;



          a {
            background:rgba(0,0,0,0.9);
            padding:10px 20px;
            font-size:36px;
            border: 1px dashed white;
            display: block;
            text-align:center;
            color:white;
          }
        }

        .leadslideUpsellPopup {
          position: absolute;
          top:45px;
          min-height:40px;
          padding:20px;
          left:0;
          right:0;
          margin:auto;
          height:auto;
          z-index:9000;
          max-height: 600px;
          overflow-y: auto;


          .close {
            position: absolute;
            top:5px;
            right:5px;
            font-size:16px;
            line-height:20px;
            text-align: center;
            width:20px;
            height:20px;
            z-index:4;
          }

          h3 {
            margin-top:0px;
          }

          .ls-pop-add-to-cart {

            .price {
              margin:5px 0 10px 0;
              span.price {
                font-weight: bold;
              }

              span.comparePrice {
                font-style: italic;
              }
            }
            .center-qtybox {
              max-width: 97px;
              margin:auto;
            }
            .lsQTY {
              max-width: 50px;
              border-radius: 2px;
              border: 1px solid grey;
              padding: 4px;
              line-height: 20px;
              margin:0 2%;
            }
          }


        }

        .qtyLabel {
          line-height:30px;
          float:left;
        }
      .lsPopHeader, .ls-pop-content-area {
        font-family: Helvetica, Arial, Sans-Serif;
      }

      }
    }
    /* Large mobiles ----------- */
    @media only screen  and (min-width : 998px)
    {
      .leadslideUpsellPopup {
        width: 75% !important;
      }
    }
    @media only screen  and (max-width : 751px) {
      .leadslideUpsellPopup {
        width:95%!important;

        .col-sm-6 {
          margin-bottom:40px !important;
        }

        .ls-pop-content-area {
          text-align:center!important;

        }

        .lsPopHeader {
          text-align:center!important;

          h3 {
            font-size:24px;
          }
        }

        .lsPopContent {
          max-height: 400px;
          overflow: auto;
        }

        .qtyAndCartOne {
          .leadSlideAddToCart {
            margin-top:20px;
            width:100%;

            .qtyLabel {
              line-height:30px;
              width:6%;
              float:left;
              margin-right:0!important;
            }

          }




          
        }





        .ls-pop-add-to-cart {
          .lsQTY {
            max-width: 1000px !important;
            width: 90%;
            float:right!important;
            margin:0 0 0 2% !important;
          }

          .center-qtybox {
            max-width:100%!important;
          }

          .price {
            text-align:center!important;
          }
          .col-sm-4 {

          }

          .col-sm-8 {
            .btn-default {
              width:100%;
            }
          }
        }
      }
    }

    @media only screen  and (max-width : 550px) {
      .ls-pop-add-to-cart {
        .lsQTY {
          max-width: 1000px !important;
          width: 80%;
          float:right!important;
          margin:0 0 0 2% !important;
        }

        .price {
          text-align:center!important;
        }
        .col-sm-4 {

        }

        .col-sm-8 {
          .btn-default {
            width:100%;
          }
        }
      }
    }
  }

  .popOpts {
    .finalChanges {
      margin-top:20px;
    }
  }


  .mcInstallInstructions {
    display:none;
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    z-index:3000;
    background:rgba(0,0,0, 0.8);

    .steps {
      display:none;
      width:60%;
      position: absolute;
      top:0%;
      background:white;
      border-radius:10px;
      padding:20px;
      margin:auto;
      left:0;
      right:0;

      .close {
        width:35px;
        height:35px;
        line-height:35px;
        font-size:30px;
        text-align:center;
        position: absolute;
        top:10px;
        right:10px;
      }
    }

    .steps.active {
      display:block;
    }
  }

  .fixed-navigation {
    z-index: 1900 !important;
  }
  .editPopOptions {
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    margin:auto;
    background: rgba(0,0,0, 0.6);
    display:none;
    width:100%;
    height:100%;
    z-index:1000;

    .popOpts {
      position: absolute;
      top:25%;
      left:0;
      right:0;
      margin:auto;
      width:65%;
      height:auto;
      display:none;

      max-height:380px;
      overflow-y: auto;

      background:white;
      padding:20px;
      border-radius: 10px;

      .closeOptsClose {
        width:20px;
        height:20px;
        font-size:18px;
        line-height:20px;
        color:$dark-grey-twelve;
        position: absolute;
        top:10px;
        right:10px;
        z-index:200;

        &:hover {
          cursor: pointer;
          cursor: hand;
          opacity:0.9;
        }
      }
    }
  }
}
.eiep-page {
  .popArea  {
    width:100%;
    min-height:350px;
    background: $dark-grey-twelve;
    margin:50px 0;
    padding:20px;
  }
  .form-group {
    position: relative;
    .tutorial {
      position: absolute;
      top:0;
      left:200px;
      padding:15px;
      background:rgba(0,0,0,.8);
      color:white;
      width:250px;
      display: none;
      z-index:1000;
    }

  }



  #refreshApi {
    position: absolute;
    top:0;
    right:15px;
  }
}

.desktop-sizing {
    .popArea {
      background: url('../images/shopify/apps/popups/couponupsellipadiphonedesktopviews/desktop.png') no-repeat;
      width:961px;
      height:766px;
      padding:0px;
      overflow: auto;
      margin:auto;

      position: relative;

      .changeBackgroundButton {
        position: absolute;
        top: 50px;
        z-index: 1000;
        left: 50px;
      }

      .popupContainmentArea {
        top:13%;
        max-height:600px;

      }
    }
}

.ipad-sizing {
  .popArea {
    background: url('../images/shopify/apps/popups/couponupsellipadiphonedesktopviews/ipad.png') no-repeat;
    width:536px;
    height:761px;
    margin:auto;

    padding:0px;
    position: relative;

    .changeBackgroundButton {
      position: absolute;
      top: 70px;
      z-index: 1000;
      left: 36px;
    }

    .popupContainmentArea {
      top:16%;
      max-height:600px;

    }
  }
}

.phone-sizing {
  .popArea {
    background: url('../images/shopify/apps/popups/couponupsellipadiphonedesktopviews/iphone.png') no-repeat;
    width:301px;
    height:634px;
    padding:0px;
    margin:auto;
    position: relative;

    .changeBackgroundButton {
      position: absolute;
      top: 90px;
      z-index: 1000;
      left: 20px;
    }

    .popupContainmentArea {
      top:16%;
      overflow: auto;
      max-height:400px;
    }
  }
}

.currentSocialIcons {
  li {
    display:block;
    position: relative;
    text-align:left;
    margin-left:10px;
    background:none !important;

    a {
      display:block;
      color:white!important;
      font-size:35px !important;
    }
  }

  .removeSocialIcon {
    width:20px;
    height:20px;
    position: absolute;
    top:-5px;
    right:-5px;
    font-size:15px;
    color:white;
    text-align: center;
    line-height:20px;

    &:hover {
      cursor: hand;
      cursor: pointer;

    }
  }
}

.icon-nav-options {
  text-align:center;
  max-width:150px;

  a {
    color:white;
  }
}
#selected-icon-holder {
  font-size:30px;
  text-align:center;
}

#iconsPop {
  position: fixed;
  display:block;
  margin: auto;
  min-height:460px;//fix for overflow
  width:98%;
  height:100%;
  background:white;
  z-index: 999999;//max  index
  border-radius:5px;
  top:0;
  bottom:0;
  left:0;
  right:0;
  padding:40px;
  box-shadow: 0px 2px 3px 1px #C6C6C6;

  .close {
    width:20px;
    height:20px;
    text-align: center;
    fnt-size:20px;
    line-height:20px;

    position:absolute;
    top:10px;
    right:10px;
  }

  .icon-holder {
    max-height:100%;
    overflow-x:auto;
    overflow-y:auto;

    &:hover {
      cursor: pointer;
      cursor: hand;
    }
    .col-md-2 {
      padding-top:2px;
      padding-bottom:2px;
      margin-bottom:10px;
      margin-top:10px;

      .icons {
        text-align:center;


        .icon {
          font-size:24px;
          color:$dark-grey-seven;
        }

        &:hover {
          box-shadow: 0px 0px 3px 1px $dark-grey-six;
        }

        .icon-name {

        }

      }

      .icons.active {
        box-shadow: 0px 0px 3px 1px $btn-success-border;
      }

    }

  }
}

.pagecreator-container {
  background:white;
}

.previewVideo {
  position: absolute;
  bottom:0;
  left:15px;
  right:0;
  top:0;
  padding:25px;
  width:90%;
  text-align: center;
  color:white;
  display:none;
  z-index:400;

  &:hover {
    background: rgba(0,0,0, 0.8);
  }

  a {
    color:white;
    font-size:20px;
    text-decoration:none;
    //text-shadow: rgb(0, 0, 0) 0px 0px 15px;
  }
}


#vidCoverBack {
  background: rgb(0, 0, 0); /* The Fallback */
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:450;
  margin:auto;
  width:100%;
  height:100%;
  display:none;
}

.leadslide-sub-pages-emails-home {
  .option {
    display:none;
  }

  .option.active {
    display:block;
  }

  #selectEmailOpts {
    li {
      padding:5px 15px;
      margin:2px;
      display: block;
      float:right;
      color:$dark-grey-twelve;
      border-bottom: 4px solid transparent;
      &:hover {
        cursor: pointer;
        cursor: hand;
        opacity:0.9;
        border-bottom: 4px solid $btn-primary-border;
      }
    }

    li.active {
      border-bottom: 4px solid $btn-primary-border;
    }
  }
}

#previewVideoBox {
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  width:480px;
  height:270px;
  margin:auto;
  z-index:500;
  display:none;
  background: rgb(0, 0, 0); /* The Fallback */
  background: rgba(0, 0, 0, 0.5);
  padding:20px;

  video {
    height: 100%!important;
  }

  .closePreviewVideo
  {
    position: absolute;
    width:30px;
    height:30px;
    font-size:22px;
    color:white;
    background: #666;
    border-radius:100%;
    top:25px;
    right:25px;
    text-align:center;
    line-height:27px;
    z-index: 200;

    &:hover {
      cursor: pointer;
      cursor: mouse;
    }

  }
}
#pagePreviewC {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  height:100%;
  margin:auto;
  background: rgb(0, 0, 0); /* The Fallback */
  background: rgba(0, 0, 0, 0.5);
  padding:10px;
  z-index:2000;

  .remove {
    display:none!important;
  }

  .closePagePreview {
    position: absolute;
    width:30px;
    height:30px;
    font-size:22px;
    color:white;
    background: #666;
    border-radius:100%;
    top:15px;
    right:15px;
    text-align:center;
    line-height:27px;
    z-index: 200;

    &:hover {
      cursor: pointer;
      cursor: mouse;

    }
  }
}

.outer-block {
  background:white;
}
.custom-block, .outer-block {
  position: relative !important;

  .inline-block {
    position:relative;
    .inline-block-options {
      position: absolute !important;
      top:-40px !important;
    }
  }

  .outer-options {
    position: absolute !important;
    top:30px !important;
    z-index:1000;

    .btn-default {
      background: $dark-grey-eight;
      color:$dark-grey-six;
      font-size: 24px;
      border-color:$dark-grey-ten;

      &:hover {
        color:white;
      }
    }
  }
}

.gallery-images {
  height:75vh;
  overflow: scroll;
}

.navbar {
  margin-bottom:0px !important;
}
.navbar-brand {
  height:auto;
  max-height:100%;
  padding:0;

  img {
    max-height:30px;
    width:auto;
  }
}

.admin-payments-index {

  .payment-wrapper {

    .membership {
      background:white;
      border-radius:5px;
      border-radius: 10px;
      padding-bottom:20px;

      .header {
        text-align:center;
        background: $dark-grey-one;
        color:white;
        padding:5px 0;
        border-radius:10px 10px 0 0;

        h3 {
          font-size:30px;
          font-weight:400;
          line-height:18px;
        }

        p {
          font-size:14px;
          line-height:18px;
        }
      }

      .membership-includes {
        padding:0px 10px
      }
      ul {
        width:100%;
        margin:0;
        padding:0;
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-radius: 0 0 10px 10px;
        li {
          line-height:55px!important;
          height: 55px;
          text-align:center;
          font-size:14px;
          line-height:18px;
          color:#444444;
          background:#f2f2f2;
          border-bottom: 1px solid #e4e4e4;
          width:100%;
          display:block;
        }
      }

      .price {
        font-size:60px;
        color:#444444;
        font-weight:bold;
        margin-top:5px;

        sup {
          vertical-align: middle;
          margin-right:-10px;
          font-size:25px;
          font-weight: bold;
        }

        .currency {
          font-size:25px;
          margin-left:-10px;
          font-weight: 400;
        }



      }

      .option {
        font-size:14px;
        margin-bottom:20px;
        margin-top:-15px;
      }

      .btn {
        font-size:24px;
        padding:12px 0;
        border-color: transparent;
      }
    }

    .membership.basic {
      .header {
        background:#48cfae;
      }
    }

    .membership.business {
      span.business
      {
        color:#b52259;
      }
      .header {
        background:#b52259;
      }
    }

    .membership.agency {
      span.agency
      {
        color:#f63671;
      }
      .header {
        background:#f63671;
      }
    }
  }
}

#tabSwitcherPP {
  width:100%;
  margin:0px auto 20px auto;
  li {
    display: block;
    float:right;
    padding:5px 10px;
    margin:0 2px 0 2px;
    border-bottom:4px solid transparent;

    &:hover {
      cursor: pointer;
      cursor: mouse;
      border-bottom:4px solid $btn-primary-border;
    }
  }

  li.active {
    font-weight:bold;
    border-bottom:4px solid $btn-primary-border;
  }
}

.admin-pagecreator-drag_and_drop, .shopify-leadslidesdk-pages-builder {
  min-width:998px;

  #updateNewBlockOrder {
    position: absolute;
    z-index:200;
    left:20px;
    margin:auto;
    right:20px;
    width:90%;
    bottom:20px;
  }

  #blocksActive {

  }
  #manageTabSwitcher {
    width:250px;
    margin:0px auto 20px auto;
    li {
      display: block;
      float:left;
      padding:5px 10px;
      margin:0 2px 0 2px;

      &:hover {
        cursor: pointer;
        cursor: mouse;
        border-bottom:4px solid $btn-primary-border;
      }
    }

    li.active {
      font-weight:bold;
      border-bottom:4px solid $btn-primary-border;
    }
  }

  .addNewBlocksToDefault {
    padding:0;
    margin-bottom:15px;

    #addMoreDefaultBlocks {
      color:$btn-primary-border;
      font-weight:bold;
      padding:5px 10px;

      &:hover {
        text-decoration: none;
        color:$dark-grey-ten;
      }
    }
  }

  #blackCover {
    position: fixed;
    width:100%;
    height:100%;
    margin:auto;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:black;
    opacity:0.8;
    z-index:1600;
  }

  #sortable-site {
    div {
      max-width:100%!important;
    }
  }
  #blocksActive {
    position: fixed;
    height:80%;
    width:80%;
    margin:auto;
    top:20px;
    bottom:20px;
    left:20px;
    right:20px;
    padding:20px;
    background:white;
    border-radius:10px;
    z-index:1700;

    li.custom {
      img {
        border:2px solid #bac0c6;
        border-radius:3px;
      }
    }
  }
  .switcherHolder {
    height:100%;

    .icon {
      width:70px;
      height:70px;
      text-align:center;
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;

      i {
        font-size:70px;
        line-height:70px;
        color:$btn-info-border;
      }
    }
  }

  .blockItems {
    ul {
      margin:0;
      padding:0;
      overflow: auto;

      li {
        display:block;
        cursor: hand;
        cursor: pointer;
        margin-bottom:5px;


        img {
          width:100%;
          height:auto;
        }
      }

    }
  }

  .closeBlockOrder {
    cursor: pointer;
    cursor: mouse;
    width: 20px;
    height:20px;
    position: absolute;
    right:10px;
    top:10px;
    font-size:15px;
    color:$dark-grey-eight;

  }
}

//Page Loader
#preLoader {
  width:100%;
  height:100%;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  z-index:9999999;
  background:url('../images/loader.png') repeat;

  .inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;

    .center-loader {

      h1 {
        font-family:open sans,helvetica,arial,sans-serif;
        text-shadow: rgb(255, 255, 255) 0px 4px 247px;
        font-size:90px;
        color:white;
      }
    }


  }
}

.currentTestimonialImageHolder {
  width:150px;
  text-align:center;

  .currentTestimonialImage{
    height:150px;
    margin-bottom:15px;
    border: 1px dashed #62727f;
  }

  a {
    color:white;
  }
}

.list-icon-block {
  margin:40px 0;

  .list-icon {
    float:left;
    clear:left;
    margin-right:10px;
    margin-bottom:10px;
    max-width:15%;
    width:15%;
  }

  .list-text-item {
    float:left;
    clear:right;
    margin-bottom:10px;
    max-width:85%;
    width:82%;

  }
}

.full-page-block {
  padding-top:10%;
}

.footer-top-block {
  .col-md-3, .col-md-4  {
    .col-md-4 {
      margin-bottom:5px;
      width:auto!important;
      padding:0px;
      min-width:30px;
    }
    .col-md-8 {
      margin-bottom:5px;
    }
  }
}

@media screen and (min-width:1800px)
{
  .video-container video.fillWidth {
    min-height:145%;
  }
}
@media screen and (max-width : 991px) {
  .footer-top-block {
    .col-md-3, .col-md-4 {
      .col-md-4 {
        text-align:center;
        float:left;
        padding:0px;
        min-width:50px;

      }

      .col-md-8 {
        text-align:left;
        float:left;
      }
    }
  }
}
/* Smartphones (portrait and landscape) ----------- */
@media screen and (max-width : 480px) {
/* Styles */

  .video-container video.fillWidth {
    min-height:145%;
  }

  .full-page-block {
    overflow:hidden;
  }
  h1, h1 span {
    font-size:180%!important;
  }

  h2, h2 span {
    font-size:160%!important;
  }

  h3, h3 span {
    font-size:150%!important;
  }

  h4, h4 span {
    font-size:130%!important;
  }

  h5, h5 span {
    font-size:120%!important;
  }

  h6, h6 span {
    font-size:100%!important;
  }

  p , p span {
    font-size:130%!important;
  }

  .list-icon-block {
    margin-bottom:30px;

    .list-icon {
      float:none;
      clear:both;
      margin-right:0px;
      margin-bottom:10px;
      width:100%;
      max-width:100%;
      text-align:center;
    }

    .list-text-item {
      float:none;
      clear:both;
      text-align:center;
      margin-bottom:10px;
      width:100%;
      max-width:100%;
    }

    .icon-block.text-left{
      text-align:center;
    }
  }

}

#menuList {

  ul {
    li {
      position:relative;
      max-width:200px;

      .removeNavItem {
        position: absolute;
        top:0;
        right:0;
        color:$btn-danger-border;

        &:hover {
          cursor: pointer;
          cursor: mouse;
        }
      }
    }
  }

}

.admin-pagecreator-block {
  .outer-block, .custom-block {
    background:none;
  }
}
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "partials/variables";
@import "partials/navbar";
@import "partials/pages";
@import "partials/admin";
@import "partials/datetime/bootstrap-datetimepicker-build";
@import "partials/pagecreator/drag_and_drop_page";
@import "partials/pages/themes/plain";
@import "partials/pages/themes/launch";
@import "partials/popups/leadslideSpecific";
@import "partials/pages/support";

.ls-new-block-photo-list {
  margin:0px;
  padding:0px;

  li {
    display: block;
    margin-bottom:20px;

    .image-item {
      height:190px;
      overflow: hidden;
      border:1px solid $dark-grey-ten;
      position: relative;

      img {
        width:100%;
        height:auto;
      }

      .inner-item {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;
        margin:auto;
        text-align:center;
        background:rgba(255,255, 255,0.8);
        z-index:22;
        .action-item {
          line-height:190px;
          text-align:center;
        }
      }
    }
  }
}
#emailBuilderBlocks .jqplaceholder, #emailBuilderBlocks .jqplaceholder {
  display: none!important;
}
.block_select_list {
  li{
    .options{
      a {
        display:block; width:100%; height:100%;
      }
    }
  }
}
.outer-block {
  .container {
    z-index:1;
    position: relative;
  }
}

#block-builder-wrapper {
  .remove-column,.insert-new-inner-block {
    display: none;
    .removeColumnBlock {

    }
  }

}

.shopify-leadslidesdk-campaigns-home {
  .funnel-block {
    background:$dark-grey-six;
    height:150px;

    a {
      display: block;
      height: 150px;
      width: 100%;
      font-size: 100px;
      line-height: 150px;
      color:$dark-grey-eight;
    }
  }

  .funnel-b-link {
    padding-top:20px;
    color:$dark-grey-eight;
    font-family: "PT Sans", helvetica, arial, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    display: block;
  }

  ul {
    li {
      display: block;
    }
  }
}



ul.checklist {
  li {
    display: block;
    font-size:18px;
  }
  .complete {
    color: #3b8049;
    text-decoration: line-through;
  }
  .incomplete {
    color: #d14d48;
  }

}
.upsell-ls-app {
  .size-box {
    float:left;
    margin-right:10px;
    background:white;

    &:hover {
      opacity: 0.7;
      cursor: hand;
      cursor: pointer;
    }
  }

  .size-box.active {
    background:#333333;
  }

  .large-size-box {
    width:30px;
    height:30px;

  }

  .medium-size-box {
    width:20px;
    height:20px;
    margin-top:5px;
  }

  .small-size-box {
    width:10px;
    height:10px;
    margin-right:20px;
    margin-top:10px;
  }

  .style-change {
    width:30px;
    float:left;
    background: #333333;
    color: white;
    text-align: center;
    margin-right: 10px;
    border-radius:3px;
    line-height:30px;
    i {
      font-size:16px;
      line-height:23px;
    }

    &:hover {
      opacity: 0.7;
      cursor: hand;
      cursor: pointer;
    }
  }
}




.activeBlock, .active-button, .active-image, .active-nav {
  outline:1px dashed $btn-info-bg;
}

/*  bhoechie tab */
div.bhoechie-tab-container{
  z-index: 10;
  background-color: #ffffff;
  padding: 0 !important;
  border-radius: 4px;
  -moz-border-radius: 4px;
  border:1px solid #ddd;
  margin-top: 20px;
  margin-left: 50px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background-clip: padding-box;
  opacity: 0.97;
  filter: alpha(opacity=97);
}
div.bhoechie-tab-menu{
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
div.bhoechie-tab-menu div.list-group{
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group>a{
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group>a .glyphicon,
div.bhoechie-tab-menu div.list-group>a .fa {
  color: $dark-grey-four;
}
div.bhoechie-tab-menu div.list-group>a:first-child{
  border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group>a:last-child{
  border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group>a.active,
div.bhoechie-tab-menu div.list-group>a.active .glyphicon,
div.bhoechie-tab-menu div.list-group>a.active .fa{
  background-color: $dark-grey-four;
  background-image: $dark-grey-four;
  color: #ffffff;
}
div.bhoechie-tab-menu div.list-group>a.active:after{
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -13px;
  border-left: 0;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  border-left: 10px solid $dark-grey-four;
}

div.bhoechie-tab-content{
  background-color: #ffffff;
  /* border: 1px solid #eeeeee; */
  padding-left: 20px;
  padding-top: 10px;
}

div.bhoechie-tab div.bhoechie-tab-content:not(.active){
  display: none;
}
.ls-bread-crumbs {
  .breadcrumb {
    background:none;
    color:$light-grey-four;
    font-family: "PT Sans", helvetica, arial, sans-serif;

    a {
      font-family: "PT Sans", helvetica, arial, sans-serif;
      color:$dark-grey-seven;
    }
    .active {
      a {
        color:$dark-grey-ten;
      }
    }
  }
}
.ls-block-form {

  .disabled {
    border:none!important;
    color:#ccc!important;
  }
  font-family: "PT Sans", helvetica, arial, sans-serif;

  label, input, textarea {
    font-family: "PT Sans", helvetica, arial, sans-serif;
    font-weight:200;
  }

  label {
    color:$light-grey-six;
  }
}
.invalid label {
  color: #a94442;
}
input.ng-invalid, textarea.ng-invalid {
  //border-color: #a94442;
  //-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //color: #a94442;
}
input.ng-valid, textarea.ng-valid
{
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  color:#3c763d;
}

#inDevelopment {
  background:url('../images/mobileprebetamessage.jpg');
  background-size:cover;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  position: fixed;
  height:100%;
  width:100%;
  z-index:10000;
  left:0;
  right:0;
  top:0;
  bottom:0;
  display:none;
  min-height: 1000px;

  .infoBox {
    margin-top:18%;
    color:white;
    .info {
      h1 {
        .regular-font {
          font-weight: normal;
          line-height:60px;
          font-size:48px;
          font-family: "PT Sans", helvetica, arial, sans-serif;
          margin-bottom:50px;

        }


      }
      p {
        font-family: "Helvetica Neue", Helvetica, "Droid Sans", sans-serif;
        font-size:18px;
      }
      .large {
        font-weight: bold;
        line-height:60px !important;
        font-size:48px !important;
        margin-bottom:0px;
        font-family: "PT Sans", helvetica, arial, sans-serif;
      }



    }
  }
  }

._720kb-datepicker-calendar {
  max-width:200px;
}
.leadslidesdk-campaigns-custom-create
{
  ._720kb-datepicker-calendar {
    max-width:101%;
  }
}

.poscent {
  background-position: center!important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $font-titles;
  font-weight: 700;
}

.btn-primary-two {
  @include button-variant($btn-primary-two-color, $btn-primary-two-bg, $btn-primary-two-border);
}

.btn-primary-three {
  @include button-variant($btn-primary-three-color, $btn-primary-three-bg, $btn-primary-three-border);
}

//.btn-dark-one {
//  @include button-variant($btn-primary-two-color, $btn-primary-two-color, $dark-grey-three);
//  color:white !important;
//
//  &:hover {
//    color:#f8f8f8 !important;
//  }
//}

.btn-danger-two {
  @include button-variant($btn-primary-two-color, $danger-two-background, $danger-two-background);
}

.btn-light-green {
  @include button-variant(white, #35dbb2, #289e7e);
}

.btn-basic {
  @include button-variant(white, #40cdaa, #289e7e);
}

.btn-business {
  @include button-variant(white, #b52259, #931b49);
}

.btn-agency {
  @include button-variant(white, #f63671, #d83067);
}

.btn-gray-one {
  @include button-variant(white, $light-grey-six, $light-grey-six);

  &:hover {
    color:white;
    background: $dark-grey-three;
  }
}

.btn-gray-three {
  @include button-variant(white, $dark-grey-seven, $light-grey-six);

  &:hover {
    color:white;
    background: $dark-grey-three;
  }
}

.btn-gray-two {
  @include button-variant(white, $dark-grey-three, $dark-grey-three);

  &:hover {
    color:white;

    background: $dark-grey-four;
  }
}

.validate-ang {
  .ng-invalid.ng-touched {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }

  .ng-valid.ng-touched {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
}




.lsButton {
  text-align:center;
  color:$light-grey-six;
  font-size:50px;
  display:block;

  &:hover {
    color: $dark-grey-three;
    text-decoration: none;
  }
}

.leadslide-service {
  .tooltip.top .tooltip-arrow {
    border-top-color:$dark-grey-three;
  }
  .tooltip {
    font-size:22px;
    left:0;
    right:0;
    width:100%;
    margin:auto;
    text-align:center;
    .tooltip-inner {
      background:$dark-grey-three;
    }
  }
}

//.btn-dark-one {
//  @include button-variant($dark-grey-two, $dark-grey-three, $dark-grey-three);
//
//  &:hover {
//    color:$dark-grey-four;
//  }
//}

.btn-dark-one {
  @include button-variant(white, $blue-two, $blue-two);
  padding:7px 17px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  &:hover {
    color:white;
  }
}

.btn-blue {
  @include button-variant($btn-primary-two-color, $blue-one, $blue-one);
}

.regular-font {
  font-weight: 400;
}

.clear-fix  {
  clear: both;
}

//fix bootstraps model
.modal-dialog {
  z-index: 1040;
}

.flash {
  //background:#F6624A;
  color:white;
  width:200px;
  position: absolute;
  right:20px;
  top:20px;
  padding:1em;
  display: none;
}

table.centered {
  th, td {
    text-align:center;
  }
}

.relativeposition {
  position: relative;
}

.admin-pagecreator-edit {
  .outer-options {
    .insertInnerBlock, .outer-new-inner-block {
      display:none!important;
    }
  }
}

//jquery ui
.ui-icon-gripsmall-diagonal-se
{
  background: url('../images/blocks/resize.png') left top;
  width:25px;
  height:25px;
  background-size:25px;
  right:15px;
  opacity: 0.9;
}

.no-padding {
  padding:0px !important;
}

.full-image {
  width:100%;
  height:auto;
}

.minusMargins {
  margin-right:-15px !important;
  margin-left:-15px !important;
}

//Responsive styling

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
/* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
/* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
/* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
/* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}
/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
/* Styles */
}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}


//published page new
.ls-table {
  .npp-name {

  }

  .npp-image {
    textr-align:left!important;
    img {

    }

  }

  .npp-url {
    textr-align:left!important;

    a {
      text-align:left!important;
      font-size:14px!important;
    }

  }

  .npp-links {
    text-align: center;
    a {

    }
  }
}
//end published page new

//general colors;
$grey-one: #E2E1E1;
$dark-grey-one: #31373D;

$light-grey-one:#C6C6C6;//shadows
$light-grey-two:#CCCCCC;//default + arrows on back buttons
$light-grey-three:#f8f8f8;//default services image block background colour
$light-grey-four:#CCCCCC;//Support popup label color
$light-grey-five:#edecec;//home page
$light-grey-six:#8a96a2;//home page

//nav items
$dark-grey-two: #8a949f; //nav link color and support title
$dark-grey-three: #262A30; //nav color
$dark-grey-four: #353D45; //nav link background hover
$dark-grey-ten: #3d454d; //nav link hover
$dark-grey-eleven: #646464; //home page
$dark-grey-twelve: #575757; //home page

//admin pages
$dark-grey-five: #E6E6E6; //content area
$dark-grey-six: #92a0b3; //single template top link colours
$dark-grey-seven: #62727f; //single template second nav link color
$dark-grey-eight: #31373d; //single template second nav link color
$dark-grey-nine: #666666; //background to single template page

$danger-two-background: #FF6666;

//default bootstrap
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$green-one: #74dbae;
$blue-one: #5bc0de;
$blue-two: #337AB7;

//Buttons
$btn-primary-two-color: white;
$btn-primary-two-bg: #74dbae;
$btn-primary-two-border: #74dbae;

$btn-primary-three-color: white;
$btn-primary-three-bg: #36fad0;
$btn-primary-three-border: #35dbb2;

//default bootstrap below
//Typeography
$font-titles: 'PT Sans', helvetica, arial, sans-serif;

//validations
$valid-color: #3c763d;
$invalid-color:#a94442;

//template default themes
$theme-one-btn-default-background: #333366;
$theme-one-btn-default-color: white;
$theme-one-btn-default-title-color: #656565;
$theme-one-btn-size:2.2vw;
$theme-one-btn-default-hover-background: #3c3c90;
$theme-one-btn-default-hover-color:white;
$theme-one-btn-default-highlight:#333366;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;
//** Headings small color
$headings-small-color:        $gray-light !default;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

//** Text muted color
$text-muted:                  $gray-light !default;

//backgrounds
.gray-background-one {
  background: $dark-grey-one;
  color:white;
}

.gray-background-two {
  background: $light-grey-five;
  color:$dark-grey-nine;
}

.regular-weight {
  font-weight: 200;
}
@import "../variables";

body {
  min-height:100%;
  margin:0;
  padding:0;

  .lsFormErrors {
    background: #ff9688!important;
    color:white!important;
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1)!important;
    padding:5px!important;
    text-align:center!important;
  }

  #leadslideOverlay, .leadslideOverlay {
    //visibility: hidden;
    position: fixed;
    left: 0px;
    top: 0px;
    width:100%;
    height:100%;
    text-align:center;
    z-index: 1000;
    background-image:url('../images/overlay-two.png');
  }

}

//defaults
.popupContainmentArea {
  font-family:"PT Sans", helvetica, arial, sans-serif;
  h1,h2,h3,h4,h5,h6,a,li,p {
    font-family:"PT Sans", helvetica, arial, sans-serif;
  }
  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
  }


  ul#countdown {
    margin: 10px auto;
    padding: 0;
  }

  img {
    max-height:300px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px; }
  .form-horizontal .form-group:before,
  .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }

  @media (min-width: 768px) {
    .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-static {
      display: inline-block; }
    .form-inline .input-group {
      display: inline-table;
      vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
    .form-inline .input-group > .form-control {
      width: 100%; }
    .form-inline .control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .radio,
    .form-inline .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
    .form-inline .radio input[type="radio"],
    .form-inline .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }


  .timer {
    color: $theme-one-btn-default-background;
    
    .timecenter {
      height:80px;
      display: table;
      text-align: right;
      width:100%;

      div {
        width:100%;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  
  ul#countdown li{
    color: $theme-one-btn-default-background;
    display: inline;
    float: left;
    font: normal bold 36px Arial;
    letter-spacing: 5px;
    margin-left: 5px;
    text-align: center;
    width: 60px;
  }
  ul#countdown span {
    padding-left: 5px;
  }
  ul#countdown  p.timeRefDays, ul#countdown  p.timeRefHours, ul#countdown  p.timeRefMinutes, ul#countdown  p.timeRefSeconds{
    font: normal normal 12px Arial;
    letter-spacing: 0;
    margin: 0;
    padding-bottom: 5px;
  }

  #leadslideFlash {
    position: absolute;
    top:0;
    right:0;
    width:200px;
    z-index:9000;
    text-align: center;
    color: #3c763d;
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    padding:5px;
  }
  * {
    box-sizing: border-box;
  }
  //responsive columns
  .form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 6px 12px;
    font-size: 18px;
    line-height: 1.42857;
    color: #555555;
    //margin-bottom:1.2em;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .close {
    font-family: "Helvetica", arial, sans-serif;
    cursor: hand;
    cursor: pointer;
    position: absolute;
    z-index: 9002;
    top:10px;
    right:20px;
  }

  //validations
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1;
  }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed;
  }
  input[type="radio"][disabled],
  input[type="checkbox"][disabled],
  input[type="radio"].disabled,
  input[type="checkbox"].disabled,
  fieldset[disabled] input[type="radio"],
  fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
  }
  .radio-inline.disabled,
  .checkbox-inline.disabled,
  fieldset[disabled] .radio-inline,
  fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
  }
  .radio.disabled label,
  .checkbox.disabled label,
  fieldset[disabled] .radio label,
  fieldset[disabled] .checkbox label {
    cursor: not-allowed;
  }

  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
  }
  a.btn.disabled,
  fieldset[disabled] a.btn {
    pointer-events: none;
  }

  .btn-default.disabled,
  .btn-default[disabled],
  fieldset[disabled] .btn-default,
  .btn-default.disabled:hover,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn-default:hover,
  .btn-default.disabled:focus,
  .btn-default[disabled]:focus,
  fieldset[disabled] .btn-default:focus,
  .btn-default.disabled.focus,
  .btn-default[disabled].focus,
  fieldset[disabled] .btn-default.focus,
  .btn-default.disabled:active,
  .btn-default[disabled]:active,
  fieldset[disabled] .btn-default:active,
  .btn-default.disabled.active,
  .btn-default[disabled].active,
  fieldset[disabled] .btn-default.active {
    background-color: rgba(39, 39, 107, 0.6);
    border-color: #ccc;
  }

  .btn-success.disabled,
  .btn-success[disabled],
  fieldset[disabled] .btn-success,
  .btn-success.disabled:hover,
  .btn-success[disabled]:hover,
  fieldset[disabled] .btn-success:hover,
  .btn-success.disabled:focus,
  .btn-success[disabled]:focus,
  fieldset[disabled] .btn-success:focus,
  .btn-success.disabled.focus,
  .btn-success[disabled].focus,
  fieldset[disabled] .btn-success.focus,
  .btn-success.disabled:active,
  .btn-success[disabled]:active,
  fieldset[disabled] .btn-success:active,
  .btn-success.disabled.active,
  .btn-success[disabled].active,
  fieldset[disabled] .btn-success.active {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
  .emailError {
    display:none;
  }

  .has-success {
    color: #3c763d;
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }
  .has-success:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
  }

  .has-warning {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }
  .has-warning:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
  }
  .has-error {
    border-color: #a94442;
    color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  }
  .has-error:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
  }

  .has-feedback label ~ .form-control-feedback {
    top: 25px;
  }
  .has-feedback label.sr-only ~ .form-control-feedback {
    top: 0;
  }
  .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
  }

  .has-success.form-control-feedback {
    color: #3c763d;
  }
  .highlight {
    color:$theme-one-btn-default-highlight;
  }
  //
  // Typography
  // --------------------------------------------------

  // Headings
  // -------------------------

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    small,
    .small {
      font-weight: normal;
      line-height: 1;
      color: $headings-small-color;
    }
  }

  h1, .h1,
  h2, .h2,
  h3, .h3 {
    margin-top: $line-height-computed;
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
      font-size: 65%;
    }
  }
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
      font-size: 75%;
    }
  }

  h1, .h1 { font-size: $font-size-h1; }
  h2, .h2 { font-size: $font-size-h2; }
  h3, .h3 { font-size: $font-size-h3; }
  h4, .h4 { font-size: $font-size-h4; }
  h5, .h5 { font-size: $font-size-h5; }
  h6, .h6 { font-size: $font-size-h6; }


  // Body text
  // -------------------------

  p {
    margin: 0 0 ($line-height-computed / 2);
  }

  .lead {
    margin-bottom: $line-height-computed;
    font-size: floor(($font-size-base * 1.15));
    font-weight: 300;
    line-height: 1.4;

    @media (min-width: $screen-sm-min) {
      font-size: ($font-size-base * 1.5);
    }
  }


  // Emphasis & misc
  // -------------------------

  // Ex: (12px small font / 14px base font) * 100% = about 85%
  small,
  .small {
    font-size: floor((100% * $font-size-small / $font-size-base));
  }

  mark,
  .mark {
    background-color: $state-warning-bg;
    padding: .2em;
  }

  // Alignment
  .text-left           { text-align: left; }
  .text-right          { text-align: right; }
  .text-center         { text-align: center; }
  .text-justify        { text-align: justify; }
  .text-nowrap         { white-space: nowrap; }

  // Transformation
  .text-lowercase      { text-transform: lowercase; }
  .text-uppercase      { text-transform: uppercase; }
  .text-capitalize     { text-transform: capitalize; }

  // Contextual colors
  .text-muted {
    color: $text-muted;
  }

  @media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
      float:left;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .col-md-1 {
      width: 8.33333%; }
    .col-md-2 {
      width: 16.66667%; }
    .col-md-3 {
      width: 25%; }
    .col-md-4 {
      width: 33.33333%; }
    .col-md-5 {
      width: 41.66667%; }
    .col-md-6 {
      width: 50%; }
    .col-md-7 {
      width: 58.33333%; }
    .col-md-8 {
      width: 66.66667%; }
    .col-md-9 {
      width: 75%; }
    .col-md-10 {
      width: 83.33333%; }
    .col-md-11 {
      width: 91.66667%; }
    .col-md-12 {
      width: 100%; }
    .col-md-pull-0 {
      right: auto; }
    .col-md-pull-1 {
      right: 8.33333%; }
    .col-md-pull-2 {
      right: 16.66667%; }
    .col-md-pull-3 {
      right: 25%; }
    .col-md-pull-4 {
      right: 33.33333%; }
    .col-md-pull-5 {
      right: 41.66667%; }
    .col-md-pull-6 {
      right: 50%; }
    .col-md-pull-7 {
      right: 58.33333%; }
    .col-md-pull-8 {
      right: 66.66667%; }
    .col-md-pull-9 {
      right: 75%; }
    .col-md-pull-10 {
      right: 83.33333%; }
    .col-md-pull-11 {
      right: 91.66667%; }
    .col-md-pull-12 {
      right: 100%; }
    .col-md-push-0 {
      left: auto; }
    .col-md-push-1 {
      left: 8.33333%; }
    .col-md-push-2 {
      left: 16.66667%; }
    .col-md-push-3 {
      left: 25%; }
    .col-md-push-4 {
      left: 33.33333%; }
    .col-md-push-5 {
      left: 41.66667%; }
    .col-md-push-6 {
      left: 50%; }
    .col-md-push-7 {
      left: 58.33333%; }
    .col-md-push-8 {
      left: 66.66667%; }
    .col-md-push-9 {
      left: 75%; }
    .col-md-push-10 {
      left: 83.33333%; }
    .col-md-push-11 {
      left: 91.66667%; }
    .col-md-push-12 {
      left: 100%; }
    .col-md-offset-0 {
      margin-left: 0%; }
    .col-md-offset-1 {
      margin-left: 8.33333%; }
    .col-md-offset-2 {
      margin-left: 16.66667%; }
    .col-md-offset-3 {
      margin-left: 25%; }
    .col-md-offset-4 {
      margin-left: 33.33333%; }
    .col-md-offset-5 {
      margin-left: 41.66667%; }
    .col-md-offset-6 {
      margin-left: 50%; }
    .col-md-offset-7 {
      margin-left: 58.33333%; }
    .col-md-offset-8 {
      margin-left: 66.66667%; }
    .col-md-offset-9 {
      margin-left: 75%; }
    .col-md-offset-10 {
      margin-left: 83.33333%; }
    .col-md-offset-11 {
      margin-left: 91.66667%; }
    .col-md-offset-12 {
      margin-left: 100%; }
  }

}

// template specific
.emailThemeOne {
  padding: 3% 7%;
  width: 50%;

  #coupon-block {

    .code {
      padding:20px;
      border:1px dashed $light-grey-four;
      text-align:center;
    }
    
    .return_to_store {
      margin-top:20px;
      text-align: center;

      a {
        color:$dark-grey-four;
      }
      
    }
  }

  #LeadslideSubmitForm {
    margin-top:2em;

    #lsEmail {
      font-size:2.2vw;
      margin-bottom:0px;
    }
  }

  .block {
    h2 {
      font-size:50px;
      font-size:3vw;
      line-height:80px;
    }
    p {
      text-align: center;

    }
    .title {
      text-align: center;
      margin-bottom: 1.3em;
    }
  }
}

.dealThemeOne {
  padding: 3% 7%;
  width: 50%;
  .block {
    text-align: center;
    .title {
      margin-bottom: 1.3em;
    }
    .content {
      color:$theme-one-btn-default-highlight;
    }
  }
  .timer-block {
    margin:20px 0;
  }
}

.dealThemeTwo {
  padding: 3% 7%;
  width: 50%;
  .block {
    text-align: center;
    .title {
      margin-bottom: 1.3em;
    }
    .content {
      color:$theme-one-btn-default-highlight;
    }
  }
  .timer-block {
    margin:20px 0 0 0;
  }

  .btn-default {
    margin:20px 0;
  }

}

.dealThemeThree {
  padding: 3% 7%;
  width: 50%;

  .timer-block {
    margin:20px 0 0 0;
  }

  .btn-default {
    margin:20px 0;
  }

}

.popupContainmentArea * {
  box-sizing: border-box;
}


//styles xl, l, m, s
.popupContainmentArea.fp {
  width:95%;
}

.popupContainmentArea.xl {
  width:60%;
}

.popupContainmentArea.lar {
  width:45%;
}

.popupContainmentArea.mlar {
  width:45%;
}

.popupContainmentArea.med {
  width:30%;
}

.popupContainmentArea.sma {
  width:25%;
}

.popupContainmentArea {
  //defaults
  background: #FFF;
  border-radius: 5px;

  min-height: 50px;
  margin: auto;
  left: 0;
  right: 0;
  top: 15%;
  position: fixed;
  z-index: 900;

  a.btn {
    display:block;
  }

  a.btn i {
    margin-right:10px;
  }

  .parentEditable {
    //padding:15px;
  }

  h1,h2,h3,h4,h5,h6, p {
    margin:0px;
    padding:0px;
  }

  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    text-shadow: 0 1px 0 #fff;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.7;
    color: #000;

    &:hover {
      text-decoration: none;
      cursor: pointer;
      opacity: 0.5;
    }
  }

  //error codes for email forms
  .popupError {
    display: none;
  }

  //buttons
  .btn {
    &:hover {
      opacity: 0.9;
    }
  }

  .popupContainmentArea .parentEditable {
    position: relative;
    z-index:2;
  }

  .homepage-hero-module {
    border-right: none;
    border-left: none;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    z-index:-1;

  }
  .no-video .video-container video,
  .touch .video-container video {
    display: none;
  }
  .no-video .video-container .poster,
  .touch .video-container .poster {
    display: block !important;
  }
  .video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    //background: #000;
    z-index:1;
  }
  .video-container .poster img {
    width: 100%;
    bottom: 0;
    position: absolute;
    z-index:0;
  }
  .video-container .filter {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
  }
  .video-container video {
    position: absolute;
    z-index: 0;
    bottom: 0;
    top:0;
    left:0;
    right:0;
    margin:auto;
    width:100%;
    height:100%;

  }
  .video-container video.fillWidth {
    width: auto!important;
    height:auto;
    min-height:100%;
    min-width:100%;
  }

  //inputs
  input[type=text],
  input[type=email] {
    text-align: center!important;
    font-size: 1.8vw;
    margin-bottom:20px;
  }

  .form-control {
    height: auto;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.cropArea {
  background: #E4E4E4;
  overflow: hidden;
  height:350px;
}

.modal-body p {
  margin-bottom:1.3em;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
.screenLoader {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  display: none;

  right:0;

  width:100%;
  height:100%;
  z-index:5000;
  background:rgba(255,255,255,0.9);

  .loading-text {
    //font-size:46px;
    //text-align:center;
    //position: absolute;
    //top:25%;
    //width:100%;
    //color:#ccc;

  }

  .loader-wrapper {
    width:150px;
    height:150px;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    margin:auto;
  }
  .loader {
    color: #666666;
    font-size: 20px;
    margin: 55px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.8s infinite linear;
    animation: load4 1.8s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
}

.coupon-block {
  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}
//responsive defaults
@media only screen and (min-width: 991px) and (max-width: 1180px) {
  .popupContainmentArea, .popupContainmentArea.xl, .popupContainmentArea.lar, .popupContainmentArea.med, .popupContainmentArea.sma {
    width: 55% ; }
  .popupContainmentArea.med
  {
    width:45%;
  }

  .popupContainmentArea.sma {
    width:35%;
  }

}
@media only screen and (max-width: 991px) {
  .popupContainmentArea, .popupContainmentArea.xl, .popupContainmentArea.lar, .popupContainmentArea.med, .popupContainmentArea.sma {
    //width: 80% !important;
  }

  .popupContainmentArea.med
  {
    //width:60%!important;
  }

  .popupContainmentArea.sma {
    //width:40%!important;
  }

  .popupContainmentArea {
    top: 10%;
  }
  .popupContainmentArea .inner-edit {
    text-align: center;
    margin-bottom: 20px; }
  .popupContainmentArea .text-block {
    //font-size: 3vw !important;
  }
  .popupContainmentArea .text-block h1, .popupContainmentArea .text-block h2 {
    font-size: 6vw; }
  .popupContainmentArea .text-block h3, .popupContainmentArea .text-block h4, .popupContainmentArea .text-block h5, .popupContainmentArea .text-block h6 {
    font-size: 4vw; }
  .popupContainmentArea .text-block p {
    font-size: 3vw; }
  .popupContainmentArea input[type=text],
  .popupContainmentArea input[type=email] {
    text-align: center;
    font-size: 3.8vw; } }

@media only screen and (max-width: 500px) {
  .popupContainmentArea, .popupContainmentArea.xl, .popupContainmentArea.lar, .popupContainmentArea.med, .popupContainmentArea.sma {
    //width: 90% !important;
  }
  //.popupContainmentArea {
  //  padding: 20px 17px 10px 17px !important;
  //  top: 2%;
  //}
  //
  //.popupContainmentArea h1, .popupContainmentArea h1 span {
  //  font-size: 30px !important;
  //  line-height: 45px !important;
  //}
  //
  //.popupContainmentArea h2, .popupContainmentArea h2 span {
  //  font-size: 25px !important;
  //  line-height: 40px !important;
  //}
  //
  //.popupContainmentArea p, .popupContainmentArea p span {
  //  font-size: 18px !important;
  //  line-height: 25px !important;
  //}
  //
  //.popupContainmentArea .form-group {
  //  margin-bottom: 10px !important;
  //}
  //
  //.popupContainmentArea .lsEmail {
  //  font-size: 25px !important;
  //  line-height: 30px !important;
  //  margin-bottom: 15px !important;
  //}
  //
  //.popupContainmentArea .formSubmit {
  //  font-size: 25px !important;
  //}
  //
  //#leadslideOverlay .leadSlideThumb {
  //  left: 0px !important;
  //  width: 100% !important;
  //  right: 0px !important;
  //  margin: auto !important;
  //}
  //
  //.popupContainmentArea .LeadslideSubmitForm {
  //  margin-bottom: 20px !important;
  //}
}

#popupHtml {
  .popupContainmentArea {

  }
}
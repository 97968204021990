//page builder specific TODO:: add to seperate css file
.product-list-select
{
  margin:0;
  padding:5px;
  background: white;
  border-radius:5px;
  display:none;

  li {
    display:block;
    clear:both;
    color:$dark-grey-four;

    ul.products {
      margin:0;
      padding:0;
      display:none;

      li {
        display:block;
        clear:both;

        &:hover {
          color:white;
          background:$btn-info-border;
        }
      }
    }

    &:hover {
      cursor: pointer;

      ul.products {
        //display:block;
      }
    }
  }
  li:first-child {

  }
  .product-image {
    width:50px;
    height:50px;
    line-height:50px;
    text-align:center;
    float:left;
  }

  .product-name {
    text-align:center;
    line-height:50px;
    float:left;
    width:80%;
  }

}
.linking-options .option {
  display:none;
}

.popupOpts {

}
.editPopUpContainment {

  font-size:30px;

  &:hover {
    color:white;
  }
}
.popupContainmentArea  {

  background: #FFF;
  border-radius: 5px;
  min-height: 50px;
  margin: auto;
  left: 0;
  right: 0;
  top: 25%;
  position: relative!important;
  z-index: 900;

  .editable-block {
    position: relative;

    .editOptions {
      position: absolute;
      width:200px;
      top:-35px;
      left:-20px;
      max-height:50px;
      display:none;

      .btn-group {
        display:block;
      }
    }

    &:hover {
      outline: 1px solid $dark-grey-eight;
    }
  }

  .activeBlock {
    outline: 1px dashed $btn-info-border;

  }
}
.admin-pagecreator-edit {
  .popupContainmentArea {
    position: absolute !important;
  }
}
.admin-popups-new, .admin-popups {
  background: #666666;
  .popupContainmentArea
  {
    position: relative;
    margin-top:2%;
  }
  .tooltip.top .tooltip-arrow {
    border-top-color:$dark-grey-three;
  }
  .tooltip {
    font-size: 22px;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: auto;
    text-align: center;
    .tooltip-inner {
      background: $dark-grey-three;
      width:100%;
      left:0;
      right:0;
      margin:auto;
    }
  }

  .pagecreator-nav {
    z-index:1000;
  }
}
